	/*
  	Flaticon icon font: Flaticon
  	Creation date: 16/02/2017 16:23
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
font-family: Flaticon;
font-size:auto;
font-style: normal;
margin-left:0px;
}

.flaticon-air-conditioner:before { content: "\f100"; }
.flaticon-apartment:before { content: "\f101"; }
.flaticon-arrows:before { content: "\f102"; }
.flaticon-bars:before { content: "\f103"; }
.flaticon-bed:before { content: "\f104"; }
.flaticon-building:before { content: "\f105"; }
.flaticon-clock:before { content: "\f106"; }
.flaticon-for-sale:before { content: "\f107"; }
.flaticon-holidays:before { content: "\f108"; }
.flaticon-internet:before { content: "\f109"; }
.flaticon-machine:before { content: "\f10a"; }
.flaticon-monitor:before { content: "\f10b"; }
.flaticon-old-telephone-ringing:before { content: "\f10c"; }
.flaticon-park:before { content: "\f10d"; }
.flaticon-people:before { content: "\f10e"; }
.flaticon-people-1:before { content: "\f10f"; }
.flaticon-people-2:before { content: "\f110"; }
.flaticon-person-enjoying-jacuzzi-hot-water-bath:before { content: "\f111"; }
.flaticon-security:before { content: "\f112"; }
.flaticon-shape:before { content: "\f113"; }
.flaticon-sign-out-option:before { content: "\f114"; }
.flaticon-social:before { content: "\f115"; }
.flaticon-square-layouting-with-black-square-in-east-area:before { content: "\f116"; }
.flaticon-summer:before { content: "\f117"; }
.flaticon-symbol:before { content: "\f118"; }
.flaticon-symbol-1:before { content: "\f119"; }
.flaticon-tag:before { content: "\f11a"; }
.flaticon-technology:before { content: "\f11b"; }
.flaticon-technology-1:before { content: "\f11c"; }
.flaticon-technology-2:before { content: "\f11d"; }
.flaticon-transport:before { content: "\f11e"; }
.flaticon-vehicle:before { content: "\f11f"; }
.flaticon-weightlifting:before { content: "\f120"; }
.flaticon-wifi:before { content: "\f121"; }