.button-theme {
    background: #95c41f;
    border: 2px solid #95c41f;
}

.button-theme:hover {
    background: #84ad1d;
    border: 2px solid #84ad1d;
}

.property-modal .modal-header .close,
.property-modal .modal-header .close:focus {
    background: #95c41f;
}

.modal-right-content h2{
    color: #84ad1d;
}

.btn-3:after {
    background: #84ad1d;
}

.rightside-navbar .btn-3:after{
    background: #95c41f;
}

.rightside-navbar .btn-3{
    color: #95c41f!important;
    border: 2px solid #95c41f!important;
}
.property-3 .read-more-btn{
    color: #95c41f;
}

.property-3 .read-more-btn:hover {
    background: #95c41f;
    color: #fff;
}

.intro-section .intro-section-inner {
    background: #95c41f;
}

.intro-section .intro-section-inner:before {
    background: #84ad1d;
}

.intro-section .intro-section-inner:after {
    background: #84ad1d;
}

.blog-3 .post-meta a {
    color: #95c41f;
}

.blog-1 h3 a:hover{
    color: #95c41f;
}

.blog-3:hover .date-box{
    background: #95c41f;
}

.bg-service-color{
    background: #84ad1d;
}

.blog-3 .date-box{
    color: #95c41f;
}

.blog-2 .date-box {
    color: #95c41f;
}

.blog-2:hover .date-box{
    background: #95c41f;
    color: #fff;
}

.blog-3:hover h4 a{
    color: #95c41f;
}

.blog-3:hover .post-meta {
    background: #95c41f;
}

a:hover {
    text-decoration: none;
}

.service-info-2:hover h5 a{
    color: #95c41f;
}

.service-info-2 .icon{
    color: #95c41f;
}

.sidebar-navigation .menu-list li a .badge {
    background-color: #95c41f;
}

#dismiss {
    color: #95c41f;
}

.sidebar-navigation ul li a:hover{
    color: #95c41f !important;
}

.sidebar-navigation .active{
    color: #95c41f;
}

.sidebar-navigation ul li ul li a:hover{
    color: #95c41f;
}

.service-info-2.df-box:before {
    background: #95c41f;
}

.get-in-touch .sidebar-contact-info:hover .icon{
    color: #95c41f;
}

.featured-tag2{
    background: #95c41f;
}

.service-info-1 i {
    color: #95c41f;
}

.service-info-1::before {
    background: #95c41f;
}

.banner .tab-btn .active > a {
    background: #95c41f!important;
}

.property-2:hover .facilities-list li i{
    color: #95c41f;
}

#page_scroller {
    background: #95c41f;
}

.btn-outline-1{
    color: #95c41f;
    border: 2px solid #95c41f;
}

.btn-outline-1:before {
    background: #95c41f;
    border-color: #95c41f;
}

.testimonials-1 .carousel-indicators .active {
    border: solid 2px #95c41f;
}

.blog-box-3 .post-meta a {
    color: #95c41f;
}

.blog-box-3 .detail h4 a:hover{
    color: #95c41f;
}

.testimonials-1 .slider-mover-right:hover{
    background: #95c41f;
    border: solid 1px #95c41f;
    color: #fff!important;
}

.testimonials-2 .testimonials-box::before {
    background: #95c41f;
}

.category .cc2 h4{
    color: #95c41f;
}

.category:hover .cc2-inner{
    background: #95c41f;
}

.btn-defaults{
    background: #95c41f;
    border: 2px solid #95c41f;
}

.btn-defaults:hover {
    color: #95c41f;
    border: 2px solid #95c41f;
}

.btn-defaults:before {
    background: #95c41f;
}

.btn-2.btn-white{
    color: #95c41f;
}

.category .cc3 .category-subtitle {
    background: #95c41f;
}

.category .cc3 h4 a:hover{
    color: #95c41f;
}

.counter-box-2 i {
    color: #95c41f;
}

.counters-3 .counter-box-3 i{
    color: #95c41f;
}

.counters-1 .counter-box-1 h1{
    color: #95c41f;
}

.counter-box-2:hover i {
    color: #fff;
    background: #95c41f;
}

.counter-box-2 h1 {
    color: #95c41f;
}

.testimonials-1 .slider-mover-left:hover{
    background: #95c41f;
    border: solid 1px #95c41f;
    color: #fff!important;
}

.blog-box-4 .ling-section a:hover{
    color: #95c41f;
}

.testimonials-box .media p{
    color: #95c41f;
}

.p2 .price-for-user .price .dolar{
    color: #95c41f;
}

.our-partners .slick-btn .carousel-control .fa{
    background: #95c41f;
}

.blog-box-3 .date-box-3 {
    color: #95c41f;
}

.blog-box-2 .detail h4 a:hover{
    color: #95c41f;
}

.copy-right a:hover{
    color: #95c41f;
}

.category h3 a:hover{
    color: #95c41f;
}

.overlay-link:hover{
    background: #95c41f;
    border:2px solid #95c41f;
}

.property-content .title a{
    color: #95c41f;
}

.setting-button{
    background: #95c41f;
}

.option-panel h2{
    color: #95c41f;
}

.list-inline-listing .active{
    background: #95c41f;
    border: solid 2px #95c41f;
}

.list-inline-listing li:hover{
    background: #95c41f;
    border: solid 2px #95c41f;
}

.checkbox-theme input[type="checkbox"]:checked + label::before {
    border: 2px solid #95c41f;
}

input[type=checkbox]:checked + label:before {
    color: #95c41f;
}

.out-line-btn{
    border: solid 2px #95c41f;
    color: #95c41f;
}

.out-line-btn:hover{
    border: solid 2px #95c41f;
    background: #95c41f;
    color: #fff;
}

.property-content .facilities-list li i{
    color: #95c41f;
}

.panel-box .panel-heading .panel-title a i{
    color: #95c41f;
}

.properties-amenities ul li i {
    color: #95c41f;
}

.properties-condition ul li i {
    color: #95c41f;
}

.banner-detail-box h3 span{
    color: #95c41f;
}

.banner-search-box{
    background: #95c41f;
}

.border-button-theme {
    border: 2px solid #95c41f;
    color: #95c41f;
}

.active-c{
    color: #95c41f;
}

.services-info-2 h3 a:hover{
    color: #95c41f;
}

.border-button-theme:hover {
    background: #95c41f;
    color: #fff !important;
}

.theme-tabs .nav-tabs > li > a {
    background: #95c41f;
}

.theme-tabs .nav-tabs > li.active > a,
.theme-tabs .nav-tabs > li > a:hover {
    color: #95c41f!important; 
}

.theme-tabs .nav-tabs > li > a::after {
    background: #95c41f;
}

.theme-tabs .tab-nav > li > a::after {
    background: #95c41f none repeat scroll 0% 0%;
}

.rightside-navbar li .button {
    /* color: #95c41f !important; */
    /* border: 1px solid #95c41f !important; */
}

.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
    border-color: #95c41f;
    border-bottom: transparent;
}

.rightside-navbar li .button:hover {
    background: #95c41f !important;
}

.modal-right-content .price{
    color: #95c41f;
}

.search-button {
    background: #95c41f;
}

.tab-btn .search-area-inner{
    border-right: solid 1px #95c41f;
}

.banner-search-box .search-button{
    background: #000;
}

.banner-search-box .search-button:hover{
    background: #37404d;
}

.sr2 .search-button{
    background: #37404d;
}

.sr2 .search-button:hover{
    background: #000;
}

.counters2 .counter-box i {
    color: #95c41f;
}

.brand-box h5{
    color: #95c41f;
}

.modal-right-content .bullets li i{
   color: #95c41f;
}

.modal-left-content .control {
    background: #95c41f;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
    color: #95c41f !important;
    border-top: solid 5px #95c41f !important;
}

.main-header .navbar-default .nav > li > a:hover {
    /* color: #95c41f; */
    /* border-top: solid 5px #95c41f; */
}

.intro-section {
    background: #95c41f;
}

.search-button:hover {
    color: #fff;
    background: #84ad1d;
}

.panel-box .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    background: #95c41f;
    color: #fff;
}

.panel-box .nav-tabs>li>a:hover {
    color: #fff;
    background: #95c41f;
}

.properties-panel-box .nav-tabs>li>a:hover {
    color: #fff;
    background: #95c41f;
}

.heading-properties p i{
    color: #95c41f;
    margin-right: 5px;
}

.heading-properties h5{
    color: #95c41f;
}

.heading-properties h3 span{
    color: #95c41f;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
    color: #95c41f;
}

.bootstrap-select .dropdown-menu li a {
    color: #95c41f;
}

.bootstrap-select .dropdown-menu li a:hover {
    background: #95c41f;
}

.bootstrap-select .dropdown-menu > .active > a,
.bootstrap-select .dropdown-menu > .active > a:focus,
.bootstrap-select .dropdown-menu > .active > a:hover {
    color: #95c41f;
}

.for-sale {
    background: #95c41f;
}

.blog-box h3 a:hover{
    color: #95c41f!important;
}

.agent-1 .agent-content h5 a:hover{
    color: #95c41f;
}

.view-all ul li a:hover{
   background: #95c41f;
    color: #fff;
}

.property-2 .content .title a{
    color: #95c41f;
}

.property-2 .active{
    background: #95c41f;
}

.dropdown-menu>li>a:hover {
    color: #95c41f;
    border-left: solid 5px #95c41f;
}

.option-bar .heading-icon{
    background: #95c41f;
}

.change-view-btn {
    color: #95c41f;
    border: solid 1px #95c41f;
}

.btn-outline{
    border: solid 1px #95c41f;
    color: #95c41f;
}

.btn-outline:hover{
    border: solid 1px #84ad1d;
    background: #84ad1d;
    color: #fff;
}

.popular .price-for-user{
    color: #95c41f;
}

.pricing-3.featured .listing-badges .featured{
    background: #95c41f;
}

.change-view-btn:hover {
    border: solid 1px #95c41f;
    background: #95c41f;
}

.active-view-btn {
    background: #95c41f;
    border: solid 1px #95c41f;
}

.active-view-btn:hover {
    border: solid 1px #95c41f;
    color: #95c41f;
}

.listing-properties-box .detail header.title a {
    color: #95c41f;
}

.listing-properties-box .detail .title::after {
    background-color: #95c41f;
}

.show-more-options, .show-more-options:hover {
    color: #95c41f;
}

.pagination > li > a:hover {
    background: #95c41f;
    border-color: #95c41f;
}

.pagination > .active > a {
    background: #95c41f;
    border-color: #95c41f;
}

.pagination > .active > a:hover {
    background: #95c41f;
    border-color: #95c41f;
}

.blog-box .detail .post-meta span a i {
    color: #95c41f;
}

.agent-2 .agent-content h1 a:hover{
    color: #95c41f;
}
.form-content-box .footer span a {
    color: #95c41f;
}

blockquote {
    border-left: 5px solid #95c41f;
}

.tab-style-2-line > .nav-tabs > li.active > a {
    border: solid 1px #95c41f !important;
    background: #95c41f;
}

.tab-style-2-line > .nav-tabs > li.active > a:hover {
    border: solid 1px #95c41f !important;
    background: #95c41f !important;;
}

.agent-2 .agent-content h3 a:hover{
    color: #95c41f;
}

.agent-detail h3 a:hover{
    color: #95c41f;
}

.read-more{
    color: #95c41f;
}

.blog-box .date-box{
    background:  #95c41f;
}

.bg-service-color:before {
    background: #95c41f;
}

.services-info-3 i{
    color: #95c41f;
}

.archives ul li a:hover{
    color: #95c41f;
}
.range-slider .ui-slider .ui-slider-handle {
    background: #95c41f;
}

.range-slider .ui-slider .ui-slider-handle {
    border: 2px solid #95c41f;
}

.banner-search-box .range-slider .ui-slider .ui-slider-handle {
    background: #fff;
}

.banner-search-box .range-slider .ui-slider .ui-slider-handle {
    border: 2px solid #fff;
}

.property-tag.featured {
    background: #95c41f;
}

.banner-detail-box h2 span{
    color: #95c41f;
}

.sr2{
    background: #95c41f;
}

.range-slider .ui-widget-header {
    background-color: #95c41f;
}

.banner-search-box .range-slider .ui-widget-header {
    background-color: #fff;
}

.category-posts ul li a:hover {
    color: #95c41f;
}

.tags-box ul li a:hover {
    border: 1px solid #95c41f;
    background: #95c41f;
}

.latest-tweet a {
    color: #95c41f;
}

.popular-posts .media-heading a:hover{
    color: #95c41f;
}

.comment-meta-author a {
    color: #95c41f;
}

.comment-meta-reply a {
    background-color: #95c41f;
}

.contact-1 .contact-details .media .media-left i {
    color: #95c41f;
    border: 1px dashed #95c41f;
}

.about-text ul li i {
    color: #95c41f;
}

.breadcrumbs li a:hover {
    color: #95c41f;
}

.top-header ul li a:hover {
    color: #95c41f;
}

.helping-center .icon {
    color: #95c41f;
}

.main-title-2 h1 a:hover{
    color: #95c41f;
}

.option-bar h4 {
    color: #95c41f;
}

.attachments a:hover{
    color: #95c41f;
}

.additional-details-list li a:hover{
    color: #95c41f;
}

.user-account-box  .content ul li .active {
    color: #95c41f;
    border-left: solid 5px #95c41f;
}

.user-account-box  .content ul li a:hover{
    color: #95c41f;
    border-left: solid 5px #95c41f;
}

.photoUpload {
    background: #fff;
    color: #95c41f;
}

table.manage-table .title-container .title h4 a{
    color: #95c41f;
}

.mega-dropdown-menu > li ul > li > a:hover,
.mega-dropdown-menu > li ul > li > a:focus {
    text-decoration: none;
    color: #95c41f;
    background-color: transparent;
}

table.manage-table .title-container .title span i{
    color: #95c41f;
}

.compare-properties h3 a:hover{
    color: #95c41f;
}

.panel-box span a{
    color: #95c41f;
}

table.manage-table td.action a:hover{
    color: #95c41f;
}

.typography-page mark.color {
    background-color: #95c41f;
}

.list-3 li:before, .list-2 li:before, .list-1 li:before {
    color: #95c41f;
}

.numbered.color.filled ol > li::before {
    border: 1px solid #95c41f;
    background-color: #95c41f;
}

.numbered.color ol > li::before {
    border: 1px solid #95c41f;
    color: #95c41f;
}

.map-marker:hover {
    background-color: #95c41f;
    cursor: pointer;
}
.map-marker:hover:before {
    border-color: #95c41f transparent transparent transparent;
}

.map-marker.featured:hover {
    background-color: #95c41f;
}
.map-marker.featured:hover:before {
    border-color: #95c41f transparent transparent transparent;
}

.map-marker .icon {
    border: 3px solid #95c41f;
}

.marker-active .map-marker {
    background-color: #95c41f;
}
.marker-active .map-marker:before {
    border-color: #95c41f transparent transparent transparent;
}

.map-properties .address i{
    color: #95c41f;
}

.map-properties-btns .border-button-theme{
    color: #95c41f!important; 
}

.map-properties-btns .border-button-theme:hover{
    color: #fff !important;
}
.map-properties .map-content h4 a{
    color: #95c41f;
}

.map-properties .map-properties-fetures span i {
    color: #95c41f;
}
.dropzone-design:hover {
    border: 2px dashed #95c41f;
}

@media (max-width: 768px) {
    .navbar-default .navbar-toggle .icon-bar {
        background: #95c41f;
    }

    .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
        background-color: #95c41f;
        color: #fff! important;
    }

    .main-header .navbar-default .nav > li > a:hover {
        background: #95c41f;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus, .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
        background-color: #95c41f!important;
    }

    .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
        border-top: solid 0px!important;
    }
}