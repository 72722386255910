/*------------------------------------------------------------------
[Main Stylesheet]

Project:    The Nest
Version:    1.1.0
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Table of contents]
    1. Top Header / .top-header
    2. Main Header / .main-header
    3. Home Page Banner (Main Home) / .banner
    4. Banner Search Box / .search-area
    5. Recent Properties / .recent-properties
    6. Articles Tips / .articles-tips
    7. Application Counter / .counters
    8. Partner / .partners-block
    9. Testimonials / .testimonial-section
    10. Map Content /.map-content
    11. Map Content Sidebar /.map-content-sidebar
    12. Single Page Banner / .sub-banner
    13. Properties List View / .listing-properties-box
    14. Properties Grid View / .properties-box
    15. Single Property Details / .properties-details-page
    16. Listing Page Sidebar /.sidebar
    17. Pricing Table /.pricing-container
    18. Agent Contact Form /.agent-widget
    19. Properties Comments /.properties-comments
    20. Help Center /.helping-center
    21. Specifications /.specifications
    22. My Profile /.my-profile
    23. My Properties Box /.my-properties-box
    24. Submit Property /.submit-property
    25. Blog /.blog-body
    26. Contact Form /.contact-form
    27. Signup/Login Wrapper /.form-content-box
    28. Pagination /.pagination
    29. Main Footer / .main-footer
    30. Sub Footer / .sub-footer
-------------------------------------------------------------------*/
/** GLOBAL CLASSES **/
html, body {
    height: 100%;
}

body {
    background: #fafafa;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #37404d;
}

.modal-dialog {
    margin: 140px auto 30px;
}

p {
    font-size: 16px;
    line-height: 26px;
    color: #535353;
}

a {
    cursor: pointer;
    color: #37404d;
    text-decoration: none !important;
}

ul {
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    color: #535353 !important;
}

a:hover {
    text-decoration: none;
}

.btn.active, .btn:active {
    box-shadow: none;
}

.btns-black {
    background: #000 !important;
    border: solid 2px #000;
    color: #fff;
}

.btns-black:hover {
    color: #fff;
    background-color: #333 !important;
    border-color: #333;
}

.bootstrap-select .btn-default.focus,
.bootstrap-select .btn-default:focus {
    color: #999 !important;
}

.delay-02s {
    animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s
}

.delay-03s {
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s
}

.delay-04s {
    animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s
}

.delay-05s {
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s
}

.delay-06s {
    animation-delay: 0.6s;
    -webkit-animation-delay: 0.6s
}

.delay-07s {
    animation-delay: 0.7s;
    -webkit-animation-delay: 0.7s
}

.delay-08s {
    animation-delay: 0.8s;
    -webkit-animation-delay: 0.8s
}

.delay-09s {
    animation-delay: 0.9s;
    -webkit-animation-delay: 0.9s
}

.delay-1s {
    animation-delay: 1s;
    -webkit-animation-delay: 1s
}

.delay-12s {
    animation-delay: 1.2s;
    -webkit-animation-delay: 1.2s
}

.delay-15s {
    animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s
}

.delay-17s {
    animation-delay: 1.7s;
    -webkit-animation-delay: 1.7s
}

.delay-20s {
    animation-delay: 2.0s;
    -webkit-animation-delay: 2.0s
}

#page_scroller {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
    cursor: pointer;
    color: #FFF;
    padding: 10px 11px;
    font-size: 12px;
    position: fixed;
    bottom: 25px;
    right: 25px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.margin-t-10 {
    margin-top: 10px;
}

.checkbox {
    padding-left: 20px;
    margin: 15px 0;
}

.checkbox label {
    display: inline-block;
    position: relative;
    padding-left: 15px;
    color: #535353;
    font-size: 13px;
    line-height: 22px;
}

.checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    margin-left: -20px;
    border: 2px solid #cccccc;
    border-radius: 10%;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.sidebar-widget .checkbox label {
    font-size: 16px;
    font-weight: 500;
}

.form-content-box .checkbox label::before {
    border: none;
}

.checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #fff;
}

input[type=checkbox]:checked + label:before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f00c";
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    font-size: 14px;
    text-align: center;
    line-height: 16px;
    font-weight: 300;
}

/* Solid Buttons Structure**/
.button-sm {
    cursor: pointer;
    padding: 10px 25px;
    letter-spacing: 1px;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    color: #555;
    background: transparent;
    transition: .5s;
    border-radius: 2px;
    border: none;
}

.button-sm:hover {
    color: #333;
}

.button-md {
    cursor: pointer;
    padding: 12px 25px 12px 25px;
    text-transform: uppercase;
    color: #555;
    background: transparent;
    transition: .5s;
    border-radius: 2px;
    border: none;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 600;
}

.button-md:hover {
    color: #333;
}

.button-lg {
    cursor: pointer;
    padding: 18px 30px;
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    color: #555;
    background: #DDD;
    transition: .5s;
    border-radius: 2px;
    border: none;
}

.button-lg:hover {
    color: #333;
}

.read-more-2 {
    cursor: pointer;
    padding: 12px 45px 10px 45px;
    letter-spacing: 1px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    color: #555;
    background: transparent;
    transition: .5s;
    border-radius: 50px;
    border: none;
}

.btn-outline-2{
    cursor: pointer;
    padding: 12px 35px 10px 35px;
    letter-spacing: 1px;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    transition: .5s;
    border-radius: 5px;
}

/** Solid Buttons Colors **/
.button-default {
    color: #555;
    background: #DDD;
}

.button-default:hover {
    color: #333;
    background: #DDD;
}

.button-theme {
    color: #FFF;
}

.button-theme:hover,
.button-theme:focus {
    color: #fff !important;
    outline: none;
}

.out-line-btn:hover,
.out-line-btn:focus {
    outline: none;
}

/** Border Buttons Colors **/
.border-button-sm {
    cursor: pointer;
    padding: 10px 12px 6px;
    letter-spacing: 1px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    color: #555;
    background: #DDD;
    transition: .5s;
    border-radius: 2px;
    border: none;
}

.border-button-default {
    color: #555;
    background: #DDD;
}

.border-button-default:hover {
    color: #333;
    background: #DDD;
}

.border-button-theme {
    background: transparent;
}

.border-button-theme:hover {
    color: #fff;
}

.button-section a {
    margin: 0 5px 5px 0;
}

.alert {
    padding: 17px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 0;
    font-size: 16px;
}

.panel-title {
    font-size: 16px;
    font-weight: 500;
}

.panel-heading {
    padding: 15px 20px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-bottom: 0!important;
}

.panel-heading h3{
    margin-bottom: 0!important;
}

.panel-body {
    padding: 20px;
    font-size: 16px;
}

/** BTN 1 **/
.btn-1 {
    padding: 0 47px 0 25px;
    line-height: 46px;
    position: relative;
    display: inline-block;
    background: none;
    border-radius: 5px;
}

.btn-1 span {
    font-size: 16px;
    font-weight: 600;
    position: relative;
    z-index: 1;
}

.btn-1 .arrow {
    width: 13px;
    height: 2px;
    background: currentColor;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 25px;
}

.btn-1 .arrow:after {
    width: 8px;
    height: 8px;
    border-right: 2px solid currentColor;
    border-top: 2px solid currentColor;
    content: "";
    position: absolute;
    top: -3px;
    right: 0;
    display: inline-block;
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.btn-1:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background: transparent;
    -moz-transition: ease all 0.9s;
    -o-transition: ease all 0.9s;
    -webkit-transition: ease all 0.9s;
    transition: ease all 0.9s;
    width: 0;
    height: 100%;
}

.btn-1:hover {
    color: #ffffff!important;
}

.btn-1:hover:before {
    width: 100%;
    color: #fff!important;
}

/** BTN 2 **/
.btn-2 {
    color: #fff;
    font-size: 16px;
    display: inline-block;
    position: relative;
    z-index: 5;
    transition: .9s ease;
    font-weight: 600;
    padding: 0 47px 0 25px;
    line-height: 46px;
    border-radius: 5px;
}

.btn-2 span {
    font-size: 16px;
    font-weight: 600;
    position: relative;
    z-index: 1;
}

.btn-2 .arrow {
    width: 13px;
    height: 2px;
    background: currentColor;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 25px;
}

.btn-2 .arrow:after {
    width: 8px;
    height: 8px;
    border-right: 2px solid currentColor;
    border-top: 2px solid currentColor;
    content: "";
    position: absolute;
    top: -3px;
    right: 0;
    display: inline-block;
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.btn-2:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .9s ease;
    z-index: -1;
}

.btn-2:hover {
    background: transparent!important;
}

.btn-2:hover:before {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.btn-2:hover:after {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.btn-2.btn-white{
    background: #fff;
    border: 2px solid #fff;
}

.btn-2.btn-white:hover {
    color: #fff;
    border: 2px solid #fff;
}

.btn-2.btn-white:before {
    background: #fff;
}

/** BTN 3 **/
.btn-3 {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    outline: none !important;
    color: #ffffff;
    text-transform: capitalize;
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
}

.btn-3:hover {
    color: #ffffff;
}

.btn-3:hover:after {
    transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    transition: transform 0.9s linear, transform 0.4s linear;
}

.btn-3:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    transform-origin: bottom center;
    transition: transform 0.9s linear, transform 0.4s linear;
    z-index: -1;
}

/** TABS **/
.theme-tabs .nav-tabs {
    border-bottom: 2px solid #DDD;
}

.nav-tabs > li.active > a,
.theme-tabs .nav-tabs > li.active > a:focus,
.theme-tabs .nav-tabs > li.active > a:hover {
    border-width: 0;
}

.theme-tabs .nav-tabs > li > a {
    border: none;
    color: #ffffff;
}

.theme-tabs .nav-tabs > li.active > a,
.theme-tabs .nav-tabs > li > a:hover {
    border: none;
    background: #fff;
}

.theme-tabs .nav-tabs > li > a::after {
    content: "";
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -1px;
    transition: all 250ms ease 0s;
    transform: scale(0);
}

.theme-tabs .nav-tabs > li.active > a::after,
.theme-tabs .nav-tabs > li:hover > a::after {
    transform: scale(1);
}

.theme-tabs .tab-nav > li > a::after {
    color: #fff;
}

.theme-tabs .tab-pane {
    padding: 15px 0;
}

.theme-tabs .tab-content {
    padding: 20px
}

.theme-tabs .nav-tabs > li {
    width: 20%;
    text-align: center;
}

.theme-tabs {
    background: #FFF none repeat scroll 0% 0%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    margin-bottom: 30px;
}

@media all and (max-width: 724px) {
    .theme-tabs .nav-tabs > li > a > span {
        display: none;
    }

    .theme-tabs .nav-tabs > li > a {
        padding: 5px 5px;
    }
}

/* Tabs panel */
.tab-style-2 {
    border: none;
    padding: 0;
    margin-bottom: 20px;
}

.tab-style-2 p{
    margin-bottom: 20px;
}

.tab-style-2 .nav>li>a {
    position: relative;
    display: block;
    padding: 10px 30px;
}


.elements-page .list-group-item {
    position: relative;
    display: block;
    padding: 15px 20px;
    margin-bottom: -1px;
    background-color: #fff;
    font-size: 15px;
    border: 1px solid #ddd;
}

.elements-page .table td, .table th {
    padding: 15px 20px!important;
    vertical-align: top;
    border-top: transparent;
    font-size: 15px;
}

.elements-page h3{
    margin:0 0 20px;
    font-size: 20px;
    font-weight: 600;
}

/** Notice start **/
.notice {
    padding: 17px 15px;
    background-color: #ffffff!important;
    margin-bottom: 10px;
    border-radius: 2px;
    border: 1px solid #eaeff5;
    border-left: 4px solid #838992;
    box-shadow: 0px 0px 10px 1px rgba(71, 85, 95,0.08);
    -webkit-box-shadow: 0px 0px 10px 1px rgba(71, 85, 95,0.08);
    -moz-box-shadow: 0px 0px 10px 1px rgba(71, 85, 95,0.08);
    font-size: 16px;
}

.notice-success {
    border-left-color: #74ba28;

}

.notice-success strong {
}

.notice-danger {
    border-left-color: #eb344f;
}

.notice-danger strong {
    color: #eb344f;
}

.notice-info strong {
    color: #1db4bd;
}

.notice-info {
    border-left-color: #1db4bd;
}

.notice-warning {
    border-left-color: #fea911;
}

.notice-warning strong {
    color: #fea911;
}

.notice strong{
    font-weight: 600;
}

.progress {
    margin-bottom: 20px;
    height: 22px;
}

.bg-success {
    background-color: #28a745 !important;
}

.bg-info {
    background-color: #17a2b8 !important;
}

.bg-warning {
    background-color: #ffc107 !important;
}

.bg-dark {
    background-color: #343a40 !important;
}

.bg-danger {
    background-color: #dc3545 !important;
}

/* Default mode */
.tab-style-2-line > .nav-tabs {
    border: none;
    margin: 0;
}

.tab-style-2-line > .nav-tabs > li {
    margin-right: 2px;
}

.tab-style-2-line > .nav-tabs > li > a {
    border: 0;
    margin-right: 0;
    color: #737373;
}

.tab-style-2-line > .nav-tabs > li > a > i {
    color: #a6a6a6;
}

.tab-style-2-line > .nav-tabs > li.open, .tab-style-2-line > .nav-tabs > li:hover {
    border-bottom: 0 solid #b2b1b1;
}

.tab-style-2 .nav-tabs {
    text-align: left !important;
}

.tab-style-2-line > .nav-tabs > li.open > a, .tab-style-2-line > .nav-tabs > li:hover > a {
    border: 0;
    background: none !important;
    color: #333333;
}

.tab-style-2-line > .nav-tabs > li.open > a > i, .tab-style-2-line > .nav-tabs > li:hover > a > i {
    color: #a6a6a6;
}

.tab-style-2-line > .nav-tabs > li.open .dropdown-menu, .tab-style-2-line > .nav-tabs > li:hover .dropdown-menu {
    margin-top: 0;
}

.tab-style-2-line > .nav-tabs > li.active {
    position: relative;
}

.tab-style-2-line > .nav-tabs > li.active > a {
    color: #fff !important;
}

.tab-style-2-line > .nav-tabs > li.active > a > i {
    color: #404040;
}

.tab-style-2-line > .tab-content {
    margin-top: -3px;
    background-color: transparent !important;
    border: 0;
    border-top: none;
    padding: 30px 0 15px;
}

.portlet .tab-style-2-line > .tab-content {
    padding-bottom: 0;
}

.elements-page .tab-style-2-line > .tab-content{
    padding: 30px 0 0;
}

/* Below tabs mode */

.tab-style-2-line.tabs-below > .nav-tabs > li > a {
    margin-top: 0;
}

.tab-style-2-line.tabs-below > .nav-tabs > li:hover {
    border-bottom: 0;
}

.tab-style-2-line.tabs-below > .nav-tabs > li.active {
    margin-bottom: -2px;
    border-bottom: 0;
}

.tab-style-2-line.tabs-below > .tab-content {
    margin-top: -10px;
    border-top: 0;
    border-bottom: 1px solid #eee;
    padding-bottom: 25px;
}

.tab-style-2-line.tabs-below > .tab-content p {
    margin-bottom: 20px;
}

/** TOP HEADER AREA START **/
.top-header {
    background-color: #111111;
}

.top-header .list-inline {
    padding: 10px 0;
}

.list-inline a {
    color: #d6d6d6;
    margin-right: 15px;
    font-size: 15px;
}

.list-inline a:hover {
    color: #bbbbbb;
}

.list-inline a i {
    margin-right: 5px;
}

.top-header ul li {
    padding: 0 5px;
    height: 40px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    color: #aaa;
}

.top-header ul li a {
    color: #d6d6d6;
}

.top-header ul li a i {
    font-size: 13px;
}

.top-header .sign-in, .top-header .sign-in:hover {
    line-height: 36px;
    font-size: 15px;
    border-radius: 2px;
}

/** MAIN HEADER AREA START **/
.main-header {
    width: 100%;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    background: #fff;
}

.navbar-default {
    background: none;
    border: none;
    margin-bottom: 0;
    border-radius: 0;
}

.logo {
    padding: 30px 0;
    float: left;
    margin-right: 40px;
}

.logo img {
    height: 40px;
}

.main-header .navbar-default .nav > li > a {
    border-top: solid 5px transparent;
    color: #515151;
    outline: none;
    cursor: pointer;
    padding: 35px 14px 40px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
    background-color: #fbfbfb;
}

.navbar-nav > li {
    z-index: 9999;
}

.dropdown-menu > li > a {
    display: block;
    padding: 15px 15px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #4a4747;
    font-size: 15px;
    white-space: nowrap;
    border-left: solid 5px transparent;
    background: #fbfbfb;
    cursor: pointer !important;
    outline: none;
}

.dropdown-menu > li > a {
    display: block;
    padding: 15px 15px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #4a4747;
    font-size: 15px;
    white-space: nowrap;
    border-left: solid 5px transparent;
    background: #fbfbfb;
    cursor: pointer !important;
    outline: none;
}


.main-header .dropdown-menu > li > a {
    padding: 15px 20px 15px 15px;
}

.dropdown-menu > li:last-child > a {
    border-bottom: none;
}

.dropdown-menu .open > a, .dropdown-menu .open > a:focus, .dropdown-menu .open > a:hover {
    background-color: #fff;
    border-bottom: 1px solid transparent;
}

.navbar-nav .dropdown-menu {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
    margin-top: 10px;
    min-width: 200px;
}

.open > .dropdown-menu {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
}

.caret-up {
    width: 0;
    height: 0;
    border-left: 4px solid rgba(0, 0, 0, 0);
    border-right: 4px solid rgba(0, 0, 0, 0);
    border-bottom: 4px solid;
    display: inline-block;
    margin-left: 2px;
    vertical-align: middle;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
    color: #4a4747;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
    color: #555;
    background-color: transparent;
}

.rightside-navbar {
    padding: 25px 0;
}

.rightside-navbar .btn-3{
    padding: 13.5px 25px 12.5px!important;
    font-weight: 400;
    border-radius: 3px;
}

.rightside-navbar .btn-3:hover{
    color: #fff!important;
}

.mega-dropdown {
   position: static !important;
}

.mega-dropdown .mega-dropdown-menu {
   padding: 30px 15px 20px;
}

.mega-dropdown-menu {
   width: 100%;
   box-shadow: none;
   -webkit-box-shadow: none;
}

.mega-dropdown-menu:before {
   content: "";
   border-bottom: 15px solid #fff;
   border-right: 17px solid transparent;
   border-left: 17px solid transparent;
   position: absolute;
   top: -15px;
   left: 285px;
   z-index: 10;
}

.mega-dropdown-menu > li > ul {
    padding: 0;
    margin: 0;
}

.mega-dropdown-menu > li > ul > li {
    list-style: none;
}

.mega-dropdown-menu > li > ul > li > a {
    display: block;
    padding: 0;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #696868;
    font-size: 15px;
    margin-bottom: 10px;
    white-space: normal;
}

.megamenu-area {
    padding: 30px;
}

.mega-dropdown-menu .dropdown-header {
   font-size: 15px;
   font-weight: 600;
   padding: 0;
   color: #696868;
   border-bottom: 1px solid #eee;
   padding-bottom: 10px;
   margin-bottom: 15px;
}

.mega-dropdown-menu form {
   margin: 3px 20px;
}

.mega-dropdown-menu .form-group {
    margin-bottom: 3px;
}

/** BANNER AREA START **/
.banner {
    position: relative;
}

.banner_video_bg {
    /*max-height: 700px;*/
}

.pattern-overlay {
    background-color: rgba(0, 0, 0, 0.35);
    min-height: 496px;
}

#wrapper_mbYTP_bgndVideo {
    z-index: -999 !important;
}

.banner{
    align-self: center !important;
}

.banner .item-100vh {
    height: 100vh;
}

.banner .item img {
    height: 100%;
    width: 100%;
}

.carousel-control {
    width: 0;
    opacity: 1;
}

.carousel-control.left {
    background: none;
}

.carousel-control.right {
    background: none;
}

.slider-mover-left {
    width: 35px;
    height: 60px;
    line-height: 60px;
    position: absolute;
    top: 45%;
    z-index: 99;
    display: inline-block;
    left: 5px;
    text-align: center;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.26);
}

.slider-mover-left i {
    font-size: 20px;
}

.slider-mover-right {
    width: 35px;
    height: 60px;
    line-height: 60px;
    position: absolute;
    top: 45%;
    z-index: 99;
    display: inline-block;
    right: 5px;
    text-align: center;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.26);
}

.slider-mover-right i {
    font-size: 20px;
}

.banner-max-height {
    max-height: 720px !important;
}

.banner-max-height img {
    max-height: 720px;
}

.banner .banner-slider-inneri {
    top: 150px;
    display: inline-block;
    text-shadow: none;
    right: 8%;
    left: 8%;
}

.banner .carousel-content {
    align-self: center !important;
    margin: 0px auto;
    color: #fff;
}

.banner .banner-slider-inner {
    top: 0%;
    display: inline-block;
    text-shadow: none;
    right: 0%;
    left: 0%;
    padding: 0;
    bottom: 0%;
    background-color: rgba(0, 0, 0, 0.31);
    display: flex !important;
}

.banner-content-left{
    align-self: center !important;
}

.banner-content {
    color: #fff;
}

.banner-content-left {
   /*margin-left: 8%;*/
   /*margin-right: 8%;*/
}

.banner .banner-slider-inner h1 {
   color: #fff;
   margin: 0 0 20px;
   font-size: 48px;
   font-weight: 700;
}

.banner .banner-slider-inner p {
    margin-bottom: 25px;
    font-size: 17px;
    font-weight: 400;
    color: #fff!important;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.banner .btn-1{
    margin: 2px 0;
}

.banner .btn-2{
    margin: 2px 0;
}

.banner-content .btn {
   margin-left: 3px;
   margin-right: 3px;
}

.banner-detail-box h3 {
   margin: 0 0 20px;
   font-size: 60px;
   font-weight: 700;
color: #fff;
}

.banner-detail-box h2 {
    margin: 0 0 20px;
    font-size: 45px;
    font-weight: 700;
    color: #fff;
}

.bdb-2{
    margin-top: 116px;
}

.banner .banner-detail-box p {
   margin: 0 0 25px;
}

.banner .tab-btn .nav>li>a {
    position: relative;
    display: block;
    padding: 7px 25px 6px;
    background: #fff;
    border-radius: 50px;
    font-weight: 400;
}

.banner .tab-btn .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    color: #555;
    cursor: default;
    border: none;
    padding: 7px 25px 6px!important;
    background: #fff;
    border-radius: 50px;
    font-weight: 400;
}

.banner .tab-btn .active > a{
    color: #fff!important;
}

.b6 .banner-slider-inner p{
    margin-bottom: 40px;
}

.b6 .banner-slider-inner h1{
    margin-bottom: 10px;
}

.tab-btn .col-pad2{
    padding: 0 7.5px;
    border-right: solid 1px #d2d2d2;
}

.tab-btn .search-area{
    padding: 10px 10px 5px;
    background-color: rgba(255, 255, 255, 0.2);
    display: inline-block;
    border-radius: 10px;
}

.tab-btn .search-area-inner{
    background: #fff!important;
    -webkit-box-shadow: 0px 0px 0px 1px rgba(255,255,255,0.2);
    display: inline-block;
    width: 100%;
    border-radius: 10px;
}

.tab-btn .search-area{
    display: inline-block;
    width: 100%;
}

.tab-btn .search-area .form-group {
    margin: 0;
}

.tab-btn .nav-tabs {
    border-bottom: 1px solid transparent;
    text-align: center!important;
    display: inline-block;
    margin-bottom: 15px;
}

.tab-btn .search-fields {
    min-height: 60px;
    padding: 0;
    box-shadow: none;
    border: none;
    background: #fff;
    font-size: 15px;
    border-radius: 3px;
    line-height: 60px;
}

.tab-btn .search-fields .btn {
    display: inline-block;
    padding: 6px 12px;
    line-height: 48px;
    margin-right: 0;
}

.tab-btn .search-button {
    cursor: pointer;
    padding: 19px 20px;
    letter-spacing: 1px;
    font-size: 16px;
    text-transform: uppercase;
    color: #FFF;
    transition: .5s;
    border-radius: 0 10px 10px 0;
    border: none;
    width: 100% !important;
}

.tab-btn .cp3{
    padding: 0;
    border-right: none;
}

.tab-btn  form .input-text {
    width: 100%;
    outline: none;
    color: #6c6c6c;
    padding: 5px 12px;
    line-height: 48px;
}

/** SEARCH AREA **/
.search-area {
    background: #e6e6e6;
    padding: 40px 0 15px;
}

.show-search-area {
    z-index: 9999;
    transition: all 0.4s;
    display: block;
}

.search-area .form-group {
    margin-bottom: 15px;
}

.search-area .btn{
    font-size: 16px;
    padding: 10px 20px 8px;
}

.bootstrap-select.btn-group .dropdown-toggle .caret {
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -2px;
    vertical-align: middle;
}

.search-area .search-button{
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
}

.sr2 .container-fluid{
    padding: 0 40px;
}

.sr2{
    padding: 40px 0 20px;
}

.sr2 .btn {
    font-size: 16px;
    height: 53px;
}

.sr2 .search-fields{
    padding: 0;
}

.sr2 .search-button {
    cursor: pointer;
    padding: 13px 20px 12px;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    height: 55px;
    text-transform: uppercase;
    color: #FFF;
    transition: .5s;
    border-radius: 2px;
    border: none;
    width: 100%;
}

.search-fields {
    min-height: 50px;
    padding: 5px 0;
    box-shadow: none;
    border: 1px solid #e0e0e0;
    background: #fff;
    font-size: 15px;
    line-height: 50px!important;
    border-radius: 3px;
}

.form-control {
    padding-left: 15px;
}

.search-fields button {
    border-radius: 1px;
    box-shadow: none;
    border: none;
    background: #fff;
}

.search-button {
    cursor: pointer;
    padding: 14px 20px 14px;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #FFF;
    transition: .5s;
    border-radius: 2px;
    border: none;
    width: 100%;
}

.search-button:hover,
.search-button:focus {
    outline: none;
    color: #fff;
}

.banner-search-box {
    max-width: 350px;
    padding: 30px 15px;
    margin-left: auto;
}

.banner-search-box .search-area {
    padding: 0;
    background: transparent;
}

.banner-search-box .min-value {
    color: #fff;
}

.banner-search-box .max-value {
    color: #fff;
}

.banner-search-box .search-fields button {
    border-radius: 1px;
    box-shadow: none;
    border: none;
}

.banner-search-box .search-fields {
    padding: 0;
    box-shadow: none;
    border-radius: 3px;
}

.banner-search-box .btn {
    display: inline-block;
    min-height: 50px;
    border-radius: 3px;
}

.banner-search-box .search-area .form-group {
    margin-bottom: 15px;
}

.banner-search-box .range-slider {
    margin: 15px 0 0;
}

/** BOOTSTRAP SELECT START **/
.bootstrap-select {
    width: 100% !important;
}

.bootstrap-select button {
    color: #717171;
    border-radius: 2px;
    outline: none;
}

.bootstrap-select .dropdown-menu li {
    padding: 0;
}

.bootstrap-select .dropdown-menu li a {
    padding: 10px;
}

.bootstrap-select .dropdown-menu {
    padding: 0 !important;
    margin: 0 0 !important;
    border: 1px solid #e0e0e0;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
    margin-top: 12px;
}

.bootstrap-select .dropdown-menu li a {
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
    outline: none;
    border: 0 !important;
}

.bootstrap-select .dropdown-menu li a:hover {
    border: none;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
}

.bootstrap-select .btn-default.active,
.bootstrap-select .btn-default:active,
.bootstrap-select .open > .dropdown-toggle.btn-default {
    color: #333;
    background-color: #eee;
    border-color: transparent !important;
    box-shadow: none !important;
}

.bootstrap-select .btn-default:hover {
    background: transparent !important;
    color: #717171!important;
    box-shadow: none !important;
}

.bootstrap-select .btn-default.active,
.bootstrap-select .btn-default:active,
.bootstrap-select .open > .dropdown-toggle.btn-default {
    color: #717171!important;
    background-color: transparent;
    border-color: #adadad;
}

.btn-default.active,
.btn-default:active,
.open > .dropdown-toggle.btn-default {
    color: #333;
    color: #717171!important;
    background-color: transparent !important;
    box-shadow: none !important;
    border-color: #adadad;
}

.bootstrap-select .dropdown-toggle:focus {
    outline: none !important;
    background: transparent !important;
}

.bootstrap-select .dropdown-menu > .active > a,
.bootstrap-select .dropdown-menu > .active > a:focus,
.bootstrap-select .dropdown-menu > .active > a:hover {
    text-decoration: none;
    background-color: #fff;
    outline: 0;
}

.bootstrap-select .bs-actionsbox, .bootstrap-select .bs-donebutton, .bs-searchbox {
    padding: 4px 8px;
    background: #EEE;
    border-bottom: 1px solid #d5d5d5;
}

.bootstrap-select .bs-searchbox .form-control {
    margin-bottom: 0;
    width: 100%;
    float: none;
    height: 36px;
    border: 1px solid #ddd;
    box-shadow: none;
}

.dropdown-menu > li > a:hover {
    text-decoration: none;
    background-color: #fff;
}

.banner-search-box .btn-default:active, .open > .dropdown-toggle.btn-default {
    color: #717171!important;
}

.banner-search-box .btn-default {
    color: #717171!important;
}

/** MAIN TITLE AREA START **/
.main-title {
    text-align: center;
    margin-bottom: 40px;
}

.main-title h1 {
    font-weight: 700;
    margin: 0 0 10px;
    text-transform: uppercase;
    font-size: 27px;
}

.main-title h1 span {
    font-weight: 700;
}

.main-title p {
    font-size: 17px;
    font-weight: 400;
}

.main-title-2 {
    margin-bottom: 20px;
}

.main-title-2 h1 {
    font-size: 20px;
    margin: 0 0 10px;
    font-weight: 600;
}

.main-title-2 h1 a {
    color: #212121;
}

.main-title-2 h1 span {
    font-weight: 600;
}

/** PROPERTY AREA START **/
.property {
    background: #fff;
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    z-index: 2;
}

.property:hover {
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.property:hover .property-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    background: linear-gradient(0,#547501bf,rgb(255 255 255 / 0%));
}

.property:hover .overlay-link {
    display: inline-block;
}

.property .hp-1 {
    height: 240px;
}

.property:hover {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.10), 0 3px 3px 0 rgba(0, 0, 0, 0.10);
}

.property-img {
    position: relative;
    display: block;
    overflow: hidden;
}

.property-img:before {
    content: "";
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    background: linear-gradient(to top,rgb(35 35 37 / 66%) 0%,rgb(35 35 37 / 13%) 35%,rgb(255 255 255 / 0%) 60%,rgb(255 255 255 / 0%) 100%);
    border-radius: 4px 4px 0 0;
    opacity: 1;
}

.property-tag {
    position: absolute;
}

.property-tag.sale {
    right: 15px;
    top: 15px;
}

.property-tag.featured {
    left: 15px;
    margin-top: 15px;
}

.property-price {
    color: #fff;
    position: absolute;
    font-weight: 600;
    font-size: 20px;
    bottom: 15px;
    right: 15px;
    z-index: 20;
}

.property .button, input[type="submit"] {
    font-size: 12px;
    font-weight: 600;
    padding: 6px 14px 4px;
    border-radius: 2px;
    color: #fff;
    border: none;
    background-color: rgb(0 0 0 / 25%);
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    z-index: 20;
}

.property-content {
    padding: 20px;
}

.property-content .title {
    font-size: 22px;
    font-weight: 600;
    margin: 0 0 5px;
}

.property-content .title a:hover {
    color: #4a4747;
}

.property-content p {
    margin: 10px 0 20px;
}

.ratings {
    color: orange;
    font-size: 12px;
}

.property-content .property-address {
    margin: 0 0 15px;
}

.property-content .property-address a {
    font-size: 15px;
    color: #535353;
}

.property-content .property-address a i {
    margin-right: 3px;
}

.property-content .facilities-list {
    padding: 0;
    margin: 0;
}

.property-content .facilities-list li {
    width: 33%;
    float: left;
    font-size: 15px;
    line-height: 29px;
}

.property-content .facilities-list li i {
    font-size: 14px;
    font-weight: 400;
}

.property-content .fl-2 li {
    width: 50%;
}

.property-content .property-footer {
    border-top: solid 1px #f1f1f1;
    padding: 17px 20px;
    color: #535353;
    font-size: 15px;
}

.property-content .property-footer .right {
    float: right;
}

.property-content .property-footer a {
    color: #535353;
}

.property-content .property-footer i {
    margin-right: 5px;
}

.property-footer a {
    color: #4a4747;
}

.properties-panel-box .panel-default {
    border-color: transparent;
}

.panel {
    background: transparent;
}

.properties-panel-box .panel {
    margin-bottom: 0;
    box-shadow: none;
}

.properties-panel-box .panel-body {
    padding: 0;
}

.property .col-pad {
    padding: 0;
}

.property-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
}

.property-overlay {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-link {
    color: #fff;
    border: 2px solid #FFF;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    margin: 3px;
    cursor: pointer;
    display: none;
}

.overlay-link:not([href]):not([tabindex]),
.overlay-link:not([href]):not([tabindex]):hover {
    color: #fff;
}

.overlay-link:hover {
    color: #fff;
}

.property-img:hover .property-overlay {
    /* background-color: rgba(0, 0, 0, 0.5); */
}

.property-img:hover .overlay-link {
    display: inline-block;
}

.property .hp-2 {
    height: 240px;
}

.property .property-content {
    padding: 0px!important;
}

.property .property-content .info{
    padding: 30px 25px 25px;
}

/** FP2 STARTED**/
.fp2 {
    -webkit-transition: .5s;
    transition: .5s;
    transition: 0.9s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.fp2:hover .featured{
    transform: rotateY(360deg
    );
}

.fp2 .featured {
    /* display: inline-block; */
    /* vertical-align: middle; */
    /* transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1); */
}

.fp2:hover .sale{
    transform: rotateY(360deg
    );
}

.fp2 .sale {
    /* display: inline-block; */
    /* vertical-align: middle; */
    /* transition: 0.9s cubic-bezier(0.24, 0.74, 0.58, 1); */
}

/** MODAL BODY DESIGN STARTED**/
.modal-raw {
    display: flex;
}

.modal-left, .modal-right {
    flex: 1;
}

.property-modal .modal-left {
    padding: 0;
}

.property-modal img{
    height: 550px!important;
}

.property-modal .modal-content {
    border: none;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
}

.modal-right-content h2{
    font-weight: 600;
    margin:0 0 5px;
    font-size: 23px;
}

.modal-right-content .location{
    margin-bottom: 20px;
}

.property-modal .modal-header {
    padding: 0;
    margin-bottom: 10px;
    border-bottom: none;
}

.property-modal .modal-header .close,
.property-modal .modal-header .close:focus {
    padding: 8px 13px;
    outline: none;
    background: #3a3a3a;
    opacity: 1;
    color: #FFF;
    border-radius: 3px;
    cursor: pointer;
}

.property-modal .modal-body {
    padding: 0 15px;
    background: #fff;
}

.modal-right {
    padding: 0;
    border-top: 1px solid #EEE;
}

#modalCarousel .carousel-item {
    max-height: 240px;
}

.modal-left-content {

}

.modal-left-content img {
    width: 100%;
    height: 100%;
}

.modal-left-content .control {
    width: 30px;
    height: 30px;
    position: absolute;
    text-align: center;
    color: #FFF;
    font-size: 24px;
    line-height: 30px;
    bottom: 0px;
    opacity: 1;
}

.modal-left-content .carousel-inner {
    display: flex;
}

.modal-left-content .carousel-inner .item {
    flex: 1;
}

.modal-left-content .item img {
    width: 100%;
}

.modal-left-content iframe {
    border: none;
    width: 100%;
    height: 550px!important;
    display: block;
}

.modal-left-content .control:hover {
    opacity: 1;
}

.modal-left-content .control-prev {
    right: 30px;
}

.modal-left-content .control-next {
    right: 0;
}

.modal-left-content .description {
    padding: 40px 25px;
}

.modal-right-content .bullets {
    color: #000;
    font-size: 15px;
    font-weight: 400;
    columns: 2;
}

.modal-right-content .bullets li {
    margin-bottom: 8px;
    position: relative;
    padding-left: 25px;
}

.modal-right-content .bullets li i {
    left: 0;
    position: absolute;
    top: 0;
}

.modal-right-content .heading p{
    margin-bottom: 0;
}

.modal-right-content .comon-section{
    padding: 20px 30px;
    border-bottom: solid 1px #bbbbbb73;
}

.modal-right-content p {
    font-size: 15px;
}

.modal-backdrop.show {
    opacity: 0.7;
}

.modal-right-content{
    padding: 10px 0;
}

.modal-right-content .price {
    float: right;
    font-size: 18px;
    font-weight: 600;
}

.modal-right-content h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 15px;
    color: #37404d;
}

.modal-right-content dl {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 15px;
}

.modal-right-content dl dt {
    float: left;
    font-weight: 500;
}

.modal-right-content .cs-none{
    border-bottom: none;
}

.modal-right-content dl dd {
    text-align: right;
    margin-bottom: 8px;
}
/** MODAL BODY DESIGN ENDED **/


/** PROPERTY 2 SECTION START **/
.property-2 {
    margin-bottom: 30px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    position: relative;
    z-index: 2;
    background: #fff;
    text-align: left;
}

.property-2:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.property-2 .property-overflow {
    overflow: hidden;
    position: relative;
    z-index: 999;
}

.property-2 .property-inner:hover .property-photo:before {
    width: 100%;
}

.property-2 .property-photo:before {
    position: absolute;
    content: '';
    background: linear-gradient(0,#95c41fc2,rgb(255 171 74 / 17%));
    right: 0;
    width: 0%;
    height: 100%;
    transition: 1s;
    z-index: 111;
}

.property-2 .content {
    padding: 20px 20px;
}

.property-2 .content .title {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 5px;
}

.property-2 .property-address a {
    font-size: 15px;
    color: #535353;
}

.property-2 .property-address{
    margin: 0;
}

.property-2 .facilities-list {
    padding: 0 20px;
    border-top: solid 1px #efe9e9;
}

.property-2 .facilities-list li {
    float: left;
    font-size: 14px;
    line-height: 40px;
    margin-right: 9px;
    padding-right: 9px;
    border-right: solid 1px #efe9e9;
    text-align: center;
}

.property-2 .facilities-list li:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}

.property-2 .price-ratings {
    position: absolute;
    font-size: 20px;
    bottom: 10px;
    right: 10px;
    text-align: right;
    z-index: 200;
}

.property-2 .featured {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 999!important;
    font-size: 10px;
    font-weight: 600;
    padding: 6px 14px;
    border-radius: 2px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
}

.property-2 .price-ratings .price {
    font-size: 17px;
    font-weight: 600;
    color: #fff;
}




.property-hp img{
    height: 268px;
}

.property-hp .property-content .info {
    padding: 40px 30px 35px;
}

.property-hp .property-content .title {
    font-size: 25px;
}

.property-hp .property-footer {
    padding: 20px 30px;
}

.listing-badges {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    display: block;
    font-size: 15px;
    padding: 0;
    overflow: hidden;
    height: 100px;
}

.listing-badges .featured {
    float: left;
    transform: rotate(317deg);
    left: -57px;
    top: 26px;
    position: relative;
    text-align: center;
    width: 200px;
    letter-spacing: 1px;
    z-index: 20;
    font-size: 12px;
    font-weight: 600;
    padding: 6px 14px 4px;
    color: #fff;
    box-shadow: 1px 2px 3px 0 rgb(2 0 0 / 20%);
}

.red{
    background: red;
}

/** PROPERTY 3 SECTION START **/
.property-3 {
    position: relative;
    height: 360px;
    overflow: hidden;
    background: #fff;
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 30px;
    transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
    width: 100%!important;
}

.property-3:hover{
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.property-3.pbg-1{
    background: url('../img/properties-13.png');
}

.property-3.pbg-2{
    background: url('../img/properties-14.png');
}

.property-3.pbg-3{
    background: url('../img/properties-15.png');
}

.property-3.pbg-4{
    background: url('../img/properties-16.png');
}

.property-3.pbg-5{
    background: url('../img/properties-17.png');
}

.property-3.pbg-6{
    background: url('../img/properties-18.png');
}

.property-3:before {
    content: "";
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    background: linear-gradient(0,#547501,rgb(255 255 255 / 0%));
    border-radius: 4px 4px 0 0;
    opacity: 1;
}

.property-3 h3 {
    -webkit-transition: .4s;
    transition: .4s;
    font-weight: 600;
    font-size: 22px;
    margin: 0 0 12px;
}

.property-3 .ling-section {
    position: absolute;
    bottom: 30px;
    padding-right: 25px;
    padding-left: 25px;
    width: 100%;
}

.property-3 h3 a{
    color: #fff;
}

.property-3 h3 a:hover{
    color: #fff;
    opacity: 0.9;
}

.property-3 .read-more-btn {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 82px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.6;
    transition: 0.6;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    color: #fff;
    padding: 9.5px 22px;
    border-radius: 3px;
    background: #fff;
}

.property-3 .read-more-btn::before {
    content: '';
    position: absolute;
    width: 0;
    -webkit-transition: .5s;
}

.property-3 .facilities-list {
    display: inline-block;
    -webkit-transition: .4s;
    transition: .4s;
}

.property-3 .facilities-list li {
    margin-right: 10px;
    float: left;
    font-size: 15px;
    line-height: normal;
    color: #fff;
    padding-right: 10px;
    border-right: solid 1px #c7c7c752;
}

.property-3 .facilities-list li:last-child{
    padding-right: 0;
    margin-right: 0;
    border-right: none;
}

.property-3:hover .read-more-btn {
    opacity: 1;
    visibility: visible;
    bottom: 0;
    text-align: left;
}

.property-3:hover h3 {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
}

.property-3:hover .facilities-list {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
}

/** Featured tag2 AREA START **/
.property-3 .featured-tag2{
    font-size: 12px;
    font-weight: 600;
    padding: 6px 14px 4px;
    border-radius: 2px;
    color: #fff;
    border: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    z-index: 20;
    left: 20px;
    margin-top: 20px;
    top: 0;
    position: absolute;
}

.featured-tag2:hover i {
    transform: rotateY(360deg);
}

.sale-tag {
    font-size: 12px;
    font-weight: 600;
    padding: 6px 14px 4px;
    border-radius: 2px;
    color: #fff;
    border: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    z-index: 20;
    right: 20px;
    margin-top: 20px;
    top: 0;
    position: absolute;
    background-color: rgb(0 0 0 / 25%);
}

/** INTRO SECTION START **/
.intro-section{
    position: relative;
}

.intro-section .intro-section-inner {
    padding: 50px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.intro-section h3 {
    font-size: 27px;
    margin: 0;
    line-height: 50px;
    color: #fff;
    font-weight: 300;
}

.intro-section .btn-2 {
    float: right;
}

.intro-section .intro-section-inner .btn-5 {
    border: 2px solid #fff;
    color: #fff;
}

.intro-section .intro-section-inner:before {
    content: "";
    width: 750px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-clip-path: polygon(0 0, 100% 0, 9% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 9% 100%, 0% 100%);
    opacity: 0.5;
}

.intro-section .intro-section-inner:after {
    content: "";
    width: 400px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%);
    clip-path: polygon(0 0, 100% 0, 70% 100%);
    opacity: 0.5;
}


/** NAV TABS START **/
.nav-tabs {
    border-bottom: 1px solid transparent;
    margin-bottom: 30px;
    text-align: center;
}

.properties-panel-box .nav > li > a {
    position: relative;
    display: block;
    padding: 10px 20px 8px;
    border-radius: 0;
    background: #fff5f5;
    color: #4a4747;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

.properties-panel-box .nav > li {
    position: relative;
    display: block;
    font-weight: 600;
    text-align: center;
}

.properties-panel-box .nav-tabs > li {
    float: none;
    margin-bottom: -1px;
    display: inline-block;
}

.properties-panel-box .nav-tabs > li > a {
    line-height: 1.42857143;
    border: none;
    margin: 4px 6px 4px 0;
}

.properties-panel-box .nav-tabs > li > a:hover {
    background: transparent;
    border-radius: 0;
}

.properties-panel-box .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    color: #fff;
    cursor: default;
    border: none;
    border-radius: 0;
    border-bottom-color: transparent;
}

.properties-panel-box .nav > li > a {
    padding: 10px 20px 8px!important;
}

.properties-panel-box .nav > li > a:hover{
    padding: 10px 20px 8px!important;
}

.properties-panel-box .tab-btn .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    padding: 10px 20px 8px!important;
}


/** LIST INLINE LISTING AREA START **/
.list-inline-listing {
    padding: 0;
    margin: 0 0 30px 0;
    text-align: center;
}

.list-inline-listing li {
    padding: 5px 25px 4px;
    margin: 0;
    border: 2px solid #fff;
    background: #fff;
    color: #535353;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    font-weight: 500;
}

.list-inline-listing li:hover {
    color: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.list-inline-listing .active {
    color: #fff;
    box-shadow: none;
}

/** RECENTLY PROPERTIES  AREA START **/
.recently-properties .our-partners .carousel-control .fa {
    font-size: 32px;
    text-shadow: none;
    color: #fff;
    top: 45%;
    font-weight: 300;
    background: #a09d9d;
    width: 35px;
    height: 35px;
    line-height: 30px;
}

.recently-properties .our-partners .carousel-control {
    width: 0%;
    background-image: none;
}

/** AGENT-SECTION AREA START **/
.agent-section .our-partners .carousel-control .fa {
    font-size: 32px;
    text-shadow: none;
    color: #fff;
    top: 45%;
    font-weight: 300;
    background: #a09d9d;
    width: 35px;
    height: 35px;
    line-height: 30px;
}

/** CUSTOMER SAY AREA START **/
.chevron-icon .our-partners .carousel-control .icon-prev {
    font-size: 21px;
    text-shadow: none;
    color: #fff;
    top: 45%;
    left: -55px;
    font-weight: 300;
    background: #a09d9d;
    width: 30px;
    height: 30px;
    line-height: 26px;
}

.chevron-icon .our-partners .carousel-control .icon-next {
    font-size: 21px;
    text-shadow: none;
    color: #fff;
    top: 45%;
    right: 10px;
    font-weight: 300;
    background: #a09d9d;
    width: 30px;
    height: 30px;
    line-height: 26px;
}

.chevron-icon .our-partners img {
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    filter: grayscale(0%);
}

/** Service info 1 start **/
.service-info-1 {
    padding: 40px 20px;
    margin-bottom: 30px;
    background: #fff;
    text-align: center;
    -webkit-transition: all 0.5s;
    transition: all 0.9s;
    position: relative;
    border-radius: 4px;
    z-index: 1;
}

.service-info-1:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.service-info-1 h3 {
    margin-bottom: 15px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    font-size: 20px;
    font-weight: 600;
}

.service-info-1 p {
    margin-bottom: 20px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.service-info-1 .read-more {
    line-height: 1;
    font-weight: 600;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    display: block;
    font-size: 16px;
}

.service-info-1 i {
    display: inline-block;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    font-size: 45px;
}

.service-info-1::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
    border-radius: 4px;
    -webkit-transition: all 0.5s;
    transition: all 0.9s;
    z-index: -1;
}

.service-info-1:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.service-info-1:hover i {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.service-info-1:hover::before {
    height: 100%;
}

.service-info-1:hover p {
    color: #fff;
}

.service-info-1:hover h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
}

.service-info-1:hover .read-more {
    color: #ffffff;
}

.service-info-1:hover i{
    color: #fff;
}

.service-info-1 .read-more:hover {
    letter-spacing: 1.5px;
}

/** Service info 2 start **/
.service-info-2 {
    margin: 0 auto 30px;
    position: relative;
    text-align: left;
    background: #fff;
    padding: 40px 30px;
    width: 100%;
}

.service-info-2 h5 {
    font-size: 21px;
    margin: 0 0 20px;
    font-weight: 600;
}

.service-info-2 .number {
    font-size: 120px;
    line-height: 1;
    color: #3a30301c;
    display: inline-block;
    position: absolute;
    z-index: 0;
    right: 20px;
    font-weight: 600;
    bottom: 10px;
    font-family: "Poppins", sans-serif;
}

.service-info-2:hover .number{
    z-index: -1;
}

.service-info-2 .detail {
    margin-left: 80px;
}

.service-info-2 .icon {
    position: absolute;
    font-size: 60px;
    display: inline-block;
    vertical-align: middle;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.service-info-2 p{
    font-size: 16px;
    margin-bottom: 0;
}

.service-info-2.df-box:hover:before {
    -webkit-transform: rotate(2deg)
    translateX(-5px) translateY(16px);
    transform: rotate(2deg)
    translateX(-5px) translateY(16px);
}

.service-info-2.df-box:before {
    content: " ";
    width: 95%;
    height: 95%;
    z-index: -3;
    position: absolute;
    top: auto;
    bottom: 1px;
    left: 1px;
    border-radius: 12px;
    background-repeat: repeat-x;
    outline: 1px solid transparent;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.service-info-2.df-box:after {
    background: #FFF;
}

.service-info-2.df-box {
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
}

.service-info-2:after{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    z-index: -1;
}

/** What are you looking for? AREA START **/
.our-service {
    text-align: center;
}




.mgn-top {
    margin-top: 30px;
}


.our-service-inner {
    background: rgba(0, 0, 0, 0.80);
    padding: 70px 0;
}

.our-service-two .content {
    padding: 0;
    background: transparent;
}

.our-service-two .content:hover {
    padding: 0;
    background: transparent;
}

.content-area {
    padding: 100px 0 70px;
}

.content-area-2 {
    padding: 70px 0;
}

.content-area-3 {
    padding: 40px 0;
}

.content-area-4 {
    padding: 70px 0;
}

.content-area-5 {
    padding-bottom: 50px;
}

.content-area-6 {
    padding: 100px 0 50px;
}

.content-area-7 {
    padding: 100px 0;
}

.content-area-8 {
    padding: 100px 0 70px;
}

.content-area-9 {
    padding-top: 100px;
}

.content-area-10 {
    padding: 100px 0 70px;
}

.content-area-11 {
    margin-bottom: 100px;
}

.content-area-12 {
    padding: 100px 0 80px;
}

.content-area-13 {
    margin-bottom: 100px;
    padding-bottom: 15px;
}

.content-area-14 {
    margin-bottom: 100px;
    padding:100px 0 20px;
}

.content-area-15 {
    padding: 100px 0 50px;
}

.content-area-16 {
    padding: 10px 0 70px;
}

.content-area-17 {
    padding: 30px 0 70px;
}

.theme img {
    margin-bottom: 30px;
}

.biography p {
    line-height: 25px;
    color: #4a4747;
    margin-bottom: 20px;
}

.biography .nav-tabs {
    border-bottom: 1px solid transparent;
    margin-bottom: 20px;
    text-align: left;
}

.additional-details-list {
    margin: 0;
    padding: 0;
}

.additional-details-list li {
    list-style: none;
    line-height: 36px;
    font-weight: 600;
    font-size: 15px;
    color: #333;
}

.additional-details-list li span {
    margin-right: 5px;
    float: left;
    font-weight: 400;
    color: #4a4747;
}

.attachments a i {
    margin-right: 10px;
}

/** OUR SERVICE THERE AREA START **/
.our-service-there {
    background: url('../img/img-7.png');
    position: relative;
    z-index: 0;
    padding-top: 100px;
    text-align: center;
}

.our-service-there:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 25%);
}

.our-service-there h1{
    color: #fff;
    font-weight: 700;
    margin: 0 0 40px;
    text-transform: uppercase;
    text-align: center;
    font-size: 27px;
}

/** PARTNERS BLOCK AREA START **/
.partners-block {
    text-align: center;
}

.partners-block .partners-block-inner{
    background: #fff;
}

.partners-block .partners-content{
    background: #fff;
}

.partners-block h3 {
    font-weight: 700;
    margin: 0 0 20px;
    text-transform: uppercase;
    font-size: 18px;
}

.our-partners .carousel-control {
    width: 0%;
    background-image: none;
}

.our-partners .carousel-control.left {
    margin-left: 15px;
}

.our-partners .carousel-control.right {
    margin-right: 15px;
}

.our-partners .carousel-control .fa {
    font-size: 38px;
    text-shadow: none;
    color: #4a4747;
    top: 38%;
    font-weight: 300;;
}

.our-partners .cloneditem-1,
.our-partners .cloneditem-2,
.our-partners .cloneditem-3 {
    display: none;
}

.our-partners .cloneditem-1,
.our-partners .cloneditem-2,
.our-partners .cloneditem-3 {
    display: none;
}

.our-partners img {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
}

.our-partners img:hover {
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    filter: grayscale(0%);
}

.our-partners .partner-box img {
    height: 50px;
}

.our-partners .partner-box {
    padding: 20px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.10), 0 3px 3px 0 rgba(0, 0, 0, 0.10);
}

/** CATEGORY AREA START **/
.category {
    overflow: hidden;
    position: relative;
    margin: 0 0 20px 0;
}

.categories .col-pad {
    padding: 0 10px;
}

.category:hover:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    background-color: #000;
    opacity: 0.4;
    z-index: -11;
    transition: 0.3s ease-out;
}

.category_bg_box {
    transition: all 0.10s;
    border-radius: 3px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 233px;
}

.cat-1-bg {
    background-image: url('../img/popular-places-1.png');
}

.cat-2-bg {
    background-image: url('../img/popular-places-2.png');
}

.cat-3-bg {
    background-image: url('../img/popular-places-4.png');
}

.cat-4-bg {
    background-image: url('../img/popular-places-3.png');
}

.cat-5-bg {
    background-image: url('../img/popular-places-4.png');
}

.cat-6-bg{
    background-image: url('../img/popular-places-5.png');
}

.cat-7-bg{
    background-image: url('../img/popular-places-6.png');
}

.category:hover .category_bg_box {
    transform: scale(1.06);
    transition: transform 0.35s ease-out;
}

.category_long_bg {
    height: 486px;
}

.category_long_bg2 {
    height: 486px;
}

.category .category-overlay {
    background: linear-gradient(to top,rgb(35 35 37 / 66%) 0%,rgb(35 35 37 / 13%) 35%,rgb(255 255 255 / 0%) 60%,rgb(255 255 255 / 0%) 100%);
    border-radius: 3px;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color .10s linear;
}

.category .category-content {
    transition: all .10s linear;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all .10s linear;
    text-align: left;
    width: 100%;
    height: 100%;
    padding: 20px;
}

.category h3 a {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.category h3 {
    margin: 0;
    position: absolute;
    letter-spacing: 1px;
    bottom: 20px;
    left: 20px;
}

.category .category-subtitle {
    padding: 5px 15px;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.36);
    -webkit-box-shadow: 0px 0px 0px 1px rgba(255,255,255,0.2);
    box-shadow: 0px 0px 0px 1px rgba(255,255,255,0.2);
    position: absolute;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    letter-spacing: .5px;
    top: 20px;
    right: 20px;
}

.category .cc2{
    float: left;
    transition: all .10s linear;
    position: absolute;
    bottom: 20px;
    left: 0;
    transition: all .10s linear;
    text-align: left;
    padding: 0 20px;
    opacity: 1;
    width: 100%;
    z-index: 0;
}

.category .cc2 .cc2-inner{
    padding: 12px 15px;
    background: #fff;
    border-radius: 5px;
}

.category .cc2 h4{
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.category .cc2 h4 span{
    color: #535353;
    font-weight: 400;
}

.category .cc3 .category-subtitle {
    padding: 5px 15px;
    border-radius: 3px;
    font-size: 14px;
    box-shadow: none;
}

.category .cc3 .info {
    margin: 0;
    position: absolute;
    letter-spacing: 1px;
    bottom: 20px;
    left: 20px;
    right: 20px;
}

.category .cc3 h4{
    margin: 0 0 10px;
}

.category .cc3 h4 a{
    color: #fff;
    font-weight: 600;
    font-size: 20px;
}

.css .category .cc3 h4{
    margin: 0;
}

.category .cc3 p{
    color: #fff;
    margin-bottom: 0;
    line-height: 23px;
    font-size: 15px;
    opacity: 0.9;
}

.cey2 .category_bg_box {
    height: 270px;
}

.cey2 .category_bg_box {
    height: 270px;
}

.cey2 .category_long_bg {
    height: 560px;
}

.cey2 .category-overlay {
    background: linear-gradient(to top,rgba(35,35,37,0.9) 0%,rgba(35,35,37,0.45) 35%,rgba(22,22,23,0) 60%,rgba(0,0,0,0) 100%);
}

.category:hover .cc2-inner{
    color: #fff!important;
}

.category:hover .cc2-inner h4{
    color: #fff;
}

.category:hover .cc2-inner h4 span{
    color: #fff;
}


/** FOOTER AREA START **/
footer {
    color: #acb4bb;
    padding: 100px 0 30px;
    background-color: #050f33;
}

footer .footer-item .newsletter input {
    color: rgb(113, 113, 113);
    border-image: initial;
    outline: none;
    border: none;
    width: 80%;
    height: 45px;
    padding: 10px 15px;
    border-radius: 0;
    font-size: 16px;
    font-weight: 500;
    outline: 0;
    background: #fff;
    border: 1px solid #fff;
    float: left;
}

.footer-item .newsletter .btn {
    width: 20%;
    border-radius: 0;
    padding: 11px 13px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    font-size: 11px;
    font-weight: 600;
    border: none;
    height: 45px;
    cursor: pointer;
    transition: .5s;
}

.footer-item .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: thin dotted;
    outline: none;
    outline-offset: -2px;
}

footer .footer-item .newsletter {
    cursor: pointer;
    color: #acb4bb;
    background: transparent;
    transition: .5s;
    border-radius: 2px;
    border: none;
}

.footer-info .personal-info li {
    color: #acb4bb;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 12px;
    position: relative;
    padding-left: 25px;
}

.footer-info .main-title-2 h1 {
    color: #e0e0e0;
    font-weight: 600;
    font-size: 22px;
}

.footer-item {
    margin-bottom: 50px;
}

.footer-item.fi2{
    margin-right: 20px;
}

.footer-info .personal-info li i {
    font-size: 15px;
    left: 0;
    position: absolute;
    top: 0;
    line-height: 27px;
}

.footer-info .personal-info li a {
    color: #acb4bb;
}

.footer-info p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #acb4bb;
}

.footer-info .links li {
    font-size: 16px;
    margin-bottom: 12px;
}

.footer-info .links li a {
    color: #acb4bb;
}

.footer-info .links li a:hover {
    color: #e0e0e0;
}

.footer-info .main-title-2 .border-3 {
    background: #737272;
}

.footer-info .popular-posts .media-heading {
    font-size: 18px;
    margin: 8px 0 10px;
    font-weight: 600;
}

.footer-info .popular-posts .media-heading a{
    color: #acb4bb;
}

.footer-info .popular-posts .media .media-left img{
    width: 65px;
}

.footer-info .popular-posts .listing-post-meta {
    font-size: 16px;
    font-weight: 500;
    color: #acb4bb;
}

.footer-info .popular-posts .listing-post-meta a{
    color: #acb4bb!important;
}

/** COPY RIGHT AREA START **/
.copy-right {
    background-color: #050f33;
    padding: 20px 0;
    border-top: solid 1px #2d2d67;
    color: #acb4bb;
    font-size: 15px;
}

.copy-right ul {
    float: right;
}

.copy-right ul li {
    display: inline-block;
}

.copy-right ul li a {
    margin-left: 2px;
    color: #c5c5c5;
    width: 45px;
    height: 45px;
    line-height: 45px;
    display: inline-block;
    border-radius: 3px;
    background: #050f33;
    border: solid 1px #2d2d67;
    text-align: center;
}

.copy-right ul li a:hover{
    color: #fff;
    border: solid 1px transparent;
}

.copy-right a {
    color: #acb4bb;
}

.copy-right p{
    line-height: 45px;
    color: #acb4bb;
    margin-bottom: 0;
}

.section-4 {
    padding-top: 145px;
}

/** VIEW ALL AREA START **/
.view-all h3 {
    margin: 0 0 30px;
    font-weight: 600;
    text-align: center;
}

.view-all ul {
    margin-bottom: 25px;
    text-align: center;
}

.view-all ul li {
    display: inline-block;
}

.view-all ul li a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    margin-bottom: 5px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    background: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.view-search {
    max-width: 400px;
    margin: 0 auto 50px;
}


.comparison .search-fields .btn{
    font-size: 16px;
    padding: 9px 20px 7px;
}

/** BRAND BOX AREA START **/
.brand-box {
    text-align: center;
    padding: 20px;
    display: inline-block;
    width: 100%;
    background: #fff;
    margin-bottom: 30px;
}

.brand-box img {
    height: 50px;
}

.brand-box h5 {
    font-weight: 700;
    margin: 20px 0 5px;
    text-transform: uppercase;
    font-size: 14px;
}

.brand-box p {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
}

/** COMPARE TABLE **/
.compare-table {
    margin-bottom: 40px;
}

.compare-table tr {
    border: 1px solid #ececec;
}

.compare-table tr:first-child {
    border-top: none;
}

.compare-table tr td:first-child {
    width: 350px;
    background: #fff;
}

.compare-table tr td {
    box-sizing: border-box;
    font-size: 16px;
    padding: 20px 15px;
    text-align: center;
    width: 395px;
    font-weight: 400;
    background: #f1f1f1;
    color: #535353;
}

.compare-table tr td .fa-times {
    color: red;
}

.compare-table tr td .fa-check {
    color: green;
}

.comparison-search {
    margin-bottom: 15px;
    text-align: center;
}

.comparison-search h3 {
    margin: 0 0 20px;
    font-size: 20px;
    font-weight: 600;
}

.compare-properties {
    padding: 0;
    border: none;
    margin-bottom: 30px;
}

.compare-properties:hover {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.10), 0 3px 3px 0 rgba(0, 0, 0, 0.10);
}

.compare-properties .caption {
    padding: 30px 25px;
}

.compare-properties .caption p{
    margin-bottom: 20px;
}

.compare-properties h3 {
    margin: 0 0 10px;
    font-weight: 600;
    font-size: 20px;
}

.compare-properties h5 {
    font-size: 20px;
    margin: 0;
}

.compare-properties .price-ratings-box {
    position: absolute;
    bottom: 0;
    right: 20px;
    padding: 15px;
    margin-bottom: 20px;
    display: block;
    letter-spacing: 1px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffc12b;
    text-align: right;
}

.compare-properties .price-ratings-box .price {
    font-weight: 600;
    margin: 0 0 10px;
}

.compare-properties .price-ratings-box .rating {
    font-size: 10px;
}

.compare-properties h5 {
    color: #fff;
}

.compare-photo {
    position: relative;
}

/** TESTIMONIAL AREA START **/
.item-inner {
    max-width: 650px;
    margin: 0 auto;
}

/** LISTINGS PARALLAX AREA START **/
.listings-parallax {
    background: url('../img/img-6.png');
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #efefef;
    padding-top: 70px;
}

.listings-parallax h1 {
    font-size: 31px;
    margin: 0 0 30px;
    font-weight: 700;
    color: #fff;
}

.listings-parallax p {
    line-height: 30px;
    font-size: 16px;
    margin-bottom: 20px;
    color: #fff;
    font-weight: 400;
    opacity: 0.9;
}

.pad {
    padding-top: 0;
}

/** PRICING 1 STARTED **/
.pricing-1 {
    text-align: center;
    margin-bottom: 30px;
    padding: 30px;
    background: #fff;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.pricing-1:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.pricing-1 .title {
    color: #3f3f3f;
    font-size: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eaeaea;
    font-weight: 600;
}

.pricing-1 .content {
    padding: 20px 0;
    border-bottom: 1px solid #eaeaea;
}

.pricing-1 .content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.pricing-1 .content ul li {
    padding: 7px 0;
    font-weight: 400;
    font-size: 16px;
    color: #808080;
}

.pricing-1 .price-for-user {
    padding: 25px 0;
    margin-bottom: 30px;
    text-align: center;
    border-bottom: 1px solid #eaeaea;
    color: #3f3f3f;
}

.pricing-1 .price-for-user .price .dolar {
    font-size: 50px;
    margin-right: 10px;
    font-weight: 700;
}

.pricing-1 .price-for-user .price .month {
    font-size: 14px;
}

.pricing-1 .price-for-user .price sup {
    top: -25px;
    left: -3px;
    font-size: 30px;
}

.pricing-btn {
    padding: 6px 30px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

/** PRICING 2 STARTED **/
.pricing-2 {
    text-align: center;
    margin-bottom: 30px;
    background: #fff;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.pricing-2:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.pricing-2 .pricing-btn {
    border-radius: 3px !important;
}

.pricing-2 .btn-1 {
    padding: 0 25px;
    line-height: 40px;
    border-radius: 5px;
}

.pricing-2 .btn-2 {
    padding: 0 25px;
    line-height: 40px;
    border-radius: 5px;
}

.pricing-2 .title {
    color: #3f3f3f;
    font-size: 20px;
    padding: 25px 0;
    font-weight: 600;
}

.pricing-2 .content {
    padding: 25px 0;
    border-bottom: 1px solid #eaeaea;
}

.pricing-2 .content ul li {
    padding: 7px 0;
    font-size: 16px;
    color: #808080;
}

.pricing-2 .price-for-user {
    padding: 25px 0;
    text-align: center;
    background: #fbf1f1;
    color: #3f3f3f;
}

.pricing-2 .button {
    padding: 25px 0;
}

.pricing-2 .price-for-user .price .dolar {
    font-size: 50px;
    margin-right: 10px;
    font-weight: 700;
}

.pricing-2 .price-for-user .price .month {
    font-size: 15px;
    font-weight: 600;
}

.pricing-2 .price-for-user .price sup {
    top: -25px;
    left: -3px;
    font-size: 30px;
    font-weight: 600;
}

/** PRICING TABLES 5 STARTED **/
.pricing-3 {
    text-align: center;
    position: relative;
    background: #fff;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.pricing-3:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.pricing-tables-3 .mb {
    margin: 0 auto 65px !important;
}

.pricing-3 .price-header {
    width: 100%;
    background: #fbf1f1;
    padding: 35px 20px;
}

.pricing-3 .price-header .title {
    margin: 0 0 10px;
    color: #212529;
    font-size: 20px;
}

.pricing-3 .price-header .price {
    font-size: 35px;
    font-weight: 300;
    margin: 0;
    color: #212529;
}

.pricing-3 .content {
    padding: 20px 20px 30px;
}

.pricing-3 .content ul {
    margin: 0 0 20px;
}

.pricing-3 .content ul li {
    padding: 8px 0;
    color: #808080;
    font-size: 16px;
}

.pricing-3 .btn-1 {
    padding: 0 25px;
    line-height: 40px;
    border-radius: 5px;
}

.pricing-3 .btn-2 {
    padding: 0 25px;
    line-height: 40px;
    border-radius: 5px;
}

.pricing-3.featured {
    margin-left: -1px;
    margin-right: -1px;
    transform: translateY(-23px);
    border-radius: 0 0 3px 3px;
    z-index: 11;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    margin-bottom: 30px;
}

.pricing-3.featured .price-header {
    padding: 47px 20px;
}

.pricing-3.featured .content {
    padding: 20px 20px 60px;
}

.pricing-3.featured .listing-badges {
    position: absolute;
    top: 0;
    z-index: 999;
    right: 0;
    width: 100%;
    display: block;
    font-size: 15px;
    padding: 0;
    overflow: hidden;
    height: 100px;
}

.pricing-3.featured .listing-badges .featured {
    float: left;
    transform: rotate(-45deg);
    left: -60px;
    top: 22px;
    position: relative;
    text-align: center;
    width: 200px;
    font-size: 13px;
    margin: 0;
    padding: 7px 10px;
    background: #fff;
    font-weight: 600;
    color: #fff;
}

.pricing-3.mb-50 {
    margin-bottom: 50px;
}

/** Counters 1 start **/
.counters-1 {
    padding: 100px 0 70px;
    background: #eaeaea;
    text-align: center;
}

.counter-box-1 .counter-box-inner {
    padding: 25px 0;
    background: #fff;
    margin-bottom: 30px;
}

.counter-box-1 h1 {
    font-weight: 800;
    margin: 0 0 10px;
    font-size: 60px;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.counter-box-1:hover h1 {
    transform: rotateY(360deg);
}

.counter-box-1 h5 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
}

/** Counters 2 start **/
.counters-2{
    background: url('../img/img-3.png');
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 0;
}

.counters-2:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 39%);
}

.counters-2 .border-r{
    border-right: solid 5px rgb(255 255 255 / 20%);
}

.counters-2 .border-l{
    border-left: solid 5px rgb(255 255 255 / 20%);
}

.counter-box-2{
    position: relative;
    z-index: 2;
    -webkit-transition: .5s;
    transition: .5s;
    overflow: hidden;
    transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
    padding: 80px 0;
    text-align: center;
}

.counter-box-2:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.counter-box-2 h1 {
    margin: 25px 0 10px;
    font-weight: 700;
    color: #fff;
}

.counter-box-2:hover h1{
    color: #fff;
}

.counter-box-2 i {
    font-size: 50px;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    width: 100px;
    height: 100px;
    line-height: 100px;
    background: #fff;
    border-radius: 100%;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.counter-box-2:hover i{
    color: #fff;
}

.counter-box-2:hover i {
    transform: rotateY(360deg);
}

.counter-box-2 p{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    color: #fff;
}

/** COUNTERS 3 AREA START **/
.counters-3 {
    padding: 80px 0 65px;
    color: #fff;
    background: url('../img/img-4.png');
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.counters-3.overview-bgi:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.40);
}

.counters-3 .sec-title-three{
    padding: 80px 80px 0 0;
}

.counters-3 h2 {
    font-weight: 700;
    margin: 0 0 10px;
    text-transform: uppercase;
    font-size: 27px;
    color: #fff;
}

.counters-3 p {
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 0;
    color: #fff;
}

.counter-box-3 {
    padding: 20px 20px 15px;
    max-width: 48%;
    margin: 0 1% 15px;
    float: left;
    background: rgba(0, 0, 0, 0.36);
    -webkit-box-shadow: 0px 0px 0px 2px rgba(255,255,255,0.2);
    box-shadow: 0px 0px 0px 2px rgba(255,255,255,0.2);
    border-radius: 5px;
    overflow: hidden;
}

.counter-box-3 .media-left{
    margin-right: 20px;
}

.counter-box-3 i {
    font-size: 48px;
    margin: 0 0 7px;
    display: inline-block;
    vertical-align: middle;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.counter-box-3:hover i{
    color: #fff!important;
}

.counter-box-3:hover i {
    transform: rotateY(360deg);
}

.counter-box-3 h1 {
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 5px;
    text-align: left;
    color: #fff;
}

.counter-box-3 p {
    font-size: 16px;
    text-align: left;
    margin: 0;
    color: #fff;
    font-weight: 400;
    letter-spacing: 1px;
}

/** OVERVIEW BGI **/
.overview-bgi {
    position: relative;
}

.overview-bgi:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.text-color {
    color: #fff !important;
}

.t-slider-l {
    width: 30px;
    height: 30px;
    line-height: 25px;
    position: absolute;
    top: 100%;
    z-index: 5;
    display: inline-block;
    border: solid 1px #fff;
    background: #fff;
    text-align: center;
    border-radius: 0;
    opacity: 1;
}

.t-slider-l i {
    font-size: 15px;
    line-height: 27px;
    color: #535353;
    border-radius: 0;
}

.t-slider-r {
    width: 30px;
    height: 30px;
    line-height: 25px;
    position: absolute;
    top: 100%;
    z-index: 5;
    display: inline-block;
    right: 0;
    border: solid 1px #fff;
    background: #fff;
    text-align: center;
    border-radius: 0;
    opacity: 1;
}

.t-slider-r i {
    font-size: 15px;
    line-height: 27px;
    color: #535353;
    border-radius: 0;
}

.pojison {
    top: 47%;
}

.agent-section-1.overview-bgi:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.agent-section-1 h1 {
    color: #e4e3e3;
    font-weight: 700;
    font-size: 27px;
    margin: 0 0 40px;
    text-transform: uppercase
}

.agent-section-1 .slider-mover-left {
    width: 30px;
    height: 50px;
    line-height: 50px;
    position: inherit;
    border-radius: 0;
    top: 45%;
    background: #15151559;
    border: solid 1px #e4e3e3;
}

.agent-section-1 .slider-mover-right {
    width: 30px;
    height: 50px;
    line-height: 50px;
    position: inherit;
    border-radius: 0;
    top: 45%;
    background: #15151559;
    border: solid 1px #e4e3e3;
}

.agent-section-1 .t-slider-r i {
    font-size: 18px;
    color: #e4e3e3;
}

.agent-section-1 .t-slider-l i {
    font-size: 18px;
    color: #e4e3e3;
}

.agent-section-1 h2 {
    font-weight: 700;
    margin: 0 0 10px;
    text-transform: uppercase;
    font-size: 27px;
    color: #fff;
}

.agent-section-1 p {
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 0;
    color: #fff;
}

.agent-section-1 .sec-title-three{
    padding: 118.5px 0 0;
}

.agent-section-1 .item{
    margin:0 30px;
}

/** SOCIAL MEDIA BIG AREA START **/
.social-media li {
    list-style: none;
    float: left;
    margin: 0 5px 5px 0;
}

.social-media ul li a {
    font-size: 18px;
    display: inline-block;
    width: 45px;
    height: 45px;
    border-radius: 3px;
    text-align: center;
    line-height: 45px;
    background: #f3f3f3;
}

.social-media ul li a:hover {
    color: #fff;
}

.img-responsive {
    width: 100%;
}

/** Agent 1 start **/
.agent-1 {
    padding: 50px 40px 30px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background-color: #fff;
    margin-bottom: 30px;
    text-align: center;
}

.agent-1:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.agent-1:before, .agent-1:after {
    content: "";
    position: absolute;
    z-index: -1;
}

.agent-1:before {
    text-align: left;
}

.agent-1 .member-name {
    font-size: 20px;
    font-weight: 600;
    margin:0 0 7px;
}

.agent-1 .member-designation{
    font-size: 17px;
}

.agent-1 .member-thumb {
    width: 170px;
    height: 170px;
    margin: 0 auto 30px;
    border-radius: 50%;
    overflow: hidden;
}

.agent-1 .member-thumb img {
    width: 100%;
}

.agent-1:hover .team-hover-content {
    visibility: visible;
    opacity: 1;
}

.agent-1 .team-hover-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    transition: .8s;
    opacity: 0;
    visibility: hidden;
}

.agent-1 .team-hover-content .member-thumb {
    width: 100%;
    height: 100%;
    border-radius: 0;
    margin-bottom: 0;
}

.agent-1 .team-hover-content .member-name-designation{
    color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    padding: 30px;
    background: linear-gradient(0,#95c41f,rgb(255 171 74 / 17%));
    margin-bottom: 0;
    padding-bottom: 70px;
}

.agent-1:hover h4 a{
    color: #fff;
}

.agent-1 .team-hover-content .member-name {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin:0 0 7px;
    transform: translateY(50px);
    transition: all .4s cubic-bezier(.76,.21,.32,.85);
    transition-delay: .3s;
    opacity: 0;
}

.agent-1 .team-hover-content .member-designation {
    transform: translateY(50px);
    transition: all .4s cubic-bezier(.76,.21,.32,.85);
    transition-delay: .2s;
    transform: translateY(50px);
    opacity: 0;
    color: #fff;
    font-size: 17px;
}

.agent-1:hover .team-hover-content .member-name {
    opacity: 1;
    transform: translateY(0);
    transition-delay: .1s;
}

.agent-1:hover .team-hover-content .member-designation {
    opacity: 1;
    transform: translateY(0);
    transition-delay: .2s;
}

.agent-1:hover .member-socials {
    opacity: 1;
    transform: translateY(0);
    transition-delay: .3s;
}

.agent-1 .member-socials {
    position: absolute;
    left: 0;
    bottom: 25px;
    width: 100%;
    transform: translateY(50px);
    transition: all .4s cubic-bezier(.76,.21,.32,.85);
    transition-delay: .1s;
    opacity: 0;
}

.agent-1 .member-socials a {
    display: inline-block;
    text-align: center;
    transition: .3s;
    margin: 0 0 3px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #fff;
    border-radius: 3px;
    display: inline-block;
    color: #37404d;
    font-size: 16px;
}

.agent-1 .member-socials a:hover{
    color: #fff;
}

.agent-1 .social-list a {
    margin: 0 0 3px;
    width: 40px;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    background: #f3f3f3;
    border-radius: 3px;
    display: inline-block;
    color: #37404d;
}

/** AGENT BOX 2 AREA START **/
strong {
    font-weight: 600;
}

.a-2 .agent-content {
    padding: 40px 30px 0;
}

/** Team 2 start **/
.agent-2 {
    margin: 0 1px;
    margin-bottom: 30px;
    background: #fff!important;
    position: relative;
    z-index: 2;
}

.agent-2:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.agent-2 .detail {
    padding: 43px 30px 0;
}

.agent-2 img {
    width: 100%;
}

.agent-2 .detail h4 {
    margin: 0 0 10px;
    font-size: 22px;
    font-weight: 600;
}

.agent-2 .detail h5 {
    margin: 0 0 20px;
    font-size: 15px;
    font-weight: 600;
    color: #5f5e5e;
}

.agent-2 .photo {
    overflow: hidden;
    position: relative;
}

.agent-2 .photo::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    background: #fff;
    left: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.agent-2 .photo img {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.agent-2 .photo::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    background: #fff;
    right: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.agent-2 .photo:hover .photo::before, .agent-2:hover .photo::after {
    width: 100%;
    height: 100%;
    opacity: 0.7;
    visibility: visible;
    background: linear-gradient(0,#95c41fc2,rgb(255 171 74 / 17%));
}

.agent-2 .detail .contact ul li {
    margin-bottom: 10px;
    font-size: 15px;
    position: relative;
}

.a-three .detail {
    padding: 50px 30px 0;
}

.agent-2 .detail .contact ul li i {
    left: 0;
    position: absolute;
}

.agent-2 .social-list li {
    display: inline-block;
    font-size: 16px;
    z-index: 999;
}

.agent-2:hover .social-list{
    z-index: 999!important;
}

.agent-2 .social-list {
    position: absolute;
    top: 15px;
    text-align: center;
    z-index: 20;
    border-radius: 5px 5px 0 0;
    left: 15px;
    width: 45px;
    color: rgb(255, 255, 255);
}

.agent-2 .social-list li a {
    margin-right: 0px;
    font-size: 17px;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    display: inline-block;
    vertical-align: middle;
    transition: 0.9s cubic-bezier(0.24, 0.74, 0.58, 1);
    color: #fff!important;
    margin-bottom: 3px;
}

.col-pad{
    padding: 0;
}

.agent-2:hover li a {
    transform: rotateY(360deg);
}

/** AGENT 3 AREA START **/
.agent-3 {
    margin-bottom: 30px;
    background: #fff;
    transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
    text-align: center;
}

.agent-3:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
}

.agent-3 .info {
    padding: 30px;
    padding-top: 15px;
    background: #fff;
}

.agent-3 .info h4{
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 600;
}

.agent-3 .info p {
    margin-bottom: 0;
    font-weight: 500;
}

.agent-3 .thumb {
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.agent-3 .thumb img {
    background: #ffffff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    padding: 6px;
}

.agent-3 .thumb ul {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px;
    z-index: 1;
    transform: translate(0, -40px);
    transition: all 0.35s ease-in-out;
    opacity: 0;
    text-align: center;
}

.agent-3 .thumb ul li {
    display: inline-block;
    margin: 0 3px;
    text-align: center;
}

.agent-3 .thumb ul li a {
    display: inline-block;
    height: 40px;
    width: 40px;
    line-height: 40px;
    color: #ffffff;
    border-radius: 3px;
    font-size: 16px;
    text-align: center;
}


.agent-3:hover .thumb ul {
    transform: translate(0, -10px);
    opacity: 1;
}

/** AGENT DETAIL AREA START **/
.agent-detail {
    background: #fff;
    margin-bottom: 30px;
}

.agent-detail h3 {
    font-weight: 600;
    margin: 0 0 15px;
    text-transform: uppercase;
}

.agent-detail h3 a {
    color: #37404d;
}

.agent-detail h5{
    font-size: 15px;
    margin: 0 0 10px;
}

.agent-detail .agent-content {
    padding: 30px 30px 20px;
}

.agent-detail .agent-theme {
    padding: 0;
}

.agent-detail .social-media ul li a {
    font-size: 15px;
    width: 40px;
    height: 40px;
    line-height: 40px;
}

.address-list {
    margin: 0 0 15px;
    padding: 0;
}

.address-list li {
    line-height: 32px;
    font-size: 16px;
}

.address-list li span {
    margin-right: 10px;
    font-weight: 600;
    float: left;
}

/** TESTIMONIALS AREA START **/
.testimonials-1 {
    width: 100%;
    padding: 100px 0;
    height: auto;
    background: #eaeaea;
    text-align: center;
}

.testimonials-1 .carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    border: solid 2px #333;
    border-radius: 0;
}

.testimonials-1 .carousel-indicators .active {
    width: 12px;
    height: 12px;
    margin: 0;
    background: transparent;
    border-radius: 0;
}

.testimonials-1 .carousel-indicators {
    bottom: -15px;
}

.testimonials-1 .testimonials-inner{
    max-width: 700px;
    margin: 0 auto;
}

.testimonials-1 .testimonials-inner .job {
    margin-bottom: 25px;
    font-size: 16px;
}

.testimonials-1 .testimonials-inner .avatar {
    max-width: 95px;
    margin: 0 auto 30px;
    border-radius: 100px;
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 0 0 rgb(0 0 0 / 10%), 0 3px 3px 0 rgb(0 0 0 / 10%);
}

.testimonials-1 .testimonials-inner .avatar img {
    width: 100%;
    border-radius: 100px;
    height: 85px;
}

.testimonials-1 .testimonials-inner h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 5px;
}

.testimonials-1 .testimonials-inner p {
    margin-bottom: 30px;
    font-size: 16px;
}

/** TESTIMONIALS 2 AREA START **/
.testimonials-2 {
    position: relative;
    padding: 100px 0 155px;
    display: inline-block;
    width: 100%;
    float: left;
    background: #eaeaea;
}

.testimonials-2 h2{
    font-weight: 700;
    margin: 0 0 10px;
    text-transform: uppercase;
    font-size: 27px;
}

.testimonials-2 p{
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 0;
}

.testimonials-2 .our-partners img {
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(100%);
    filter: grayscale(0%);
}

.testimonials-2 .sec-title-three{
    padding: 102px 0;
}

.testimonials-2 .testimonials-box{
    padding: 40px;
    background: #fff;
    -webkit-transition: all 0.5s;
    transition: all 0.9s;
    position: relative;
    text-align: left;
    z-index: 1;
}

.testimonials-2 .testimonials-box:hover {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.10), 0 3px 3px 0 rgba(0, 0, 0, 0.10);
}

.testimonials-2 .testimonials-box::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    -webkit-transition: all 0.5s;
    transition: all 0.9s;
    z-index: -1;
}

.testimonials-2 .testimonials-box:hover::before {
    height: 100%;
}

.testimonials-2 .testimonials-box:hover p {
    color: #fff!important;
}

.testimonials-2 .testimonials-box p {
    margin-bottom: 20px;
    font-size: 16px;
}

.testimonials-2 .testimonials-box h5 {
    font-weight: 600;
    font-size: 18px;
    margin: 15px 0 3px;
}

.testimonials-2 .testimonials-box .media p {
    margin-bottom: 0;
}

.testimonials-2 .testimonials-box:hover h5 a {
    color: #fff;
}

.testimonials-2 .testimonials-box .media img {
    width: 65px;
    height: 65px;
    margin-right: 5px;
    border-radius: 50px;
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, .4);
    display: inline-block;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.testimonials-2 .testimonials-box:hover img {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

/** TESTIMONIALS 3 **/
.testimonials-3 {
    padding: 100px 0 155px;
    background: url('../img/img-5.png');
    z-index: 0;
}

.testimonials-3.overview-bgi:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 13%);
}

.testimonials-3 h1 {
    color: #fff;
    font-weight: 700;
    font-size: 27px;
    margin: 0 0 40px;
    text-align: center;
    text-transform: uppercase;
}

.testimonials-3 .testimonials-inner {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    -webkit-transition: .5s;
    transition: .5s;
    overflow: hidden;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.testimonials-3 .testimonials-inner .user {
    background-color: #DDDDDD;
    border-radius: 50%;
    height: 120px;
    width: 120px;
    left: 0;
    position: absolute;
    top: 30px;
    transition: background-color .15s linear;
    display: block;
    z-index: 999!important;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}


.testimonials-3 .testimonials-inner:hover .user {
    transform: rotateY(360deg);
    color: #fff;
}

.testimonials-3 .testimonials-inner a img {
    bottom: 2px;
    border-radius: 50%;
    display: block;
    height: 120px;
    width: 120px;
    position: absolute;
    top: 0px;
    box-shadow: 1px 2px 3px 0 rgb(0 0 0 / 40%);
}

.testimonials-3 .testimonials-inner .testimonial-info {
    margin: 0 0 0 60px;
    padding: 40px 30px 40px 90px;
    background: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    border-radius: 5px;
}

.testimonials-3 .testimonials-inner h3 {
    font-size: 20px;
    margin:0 0 5px;
    font-weight: 600;
}

.testimonials-3 .testimonials-inner .rating {
    font-size: 16px;
}

.testimonials-3 .testimonials-inner .rating i {
    color: orange;
}

.pr-0{
    padding-right: 0;
}

/** SLICK BTN AREA START **/
.slick-btn {
    max-width: 80px!important;
    margin: 0 auto;
    position: relative;
    top: 30px;
}

.our-partners .slick-btn .carousel-control .fa {
    width: 35px;
    height: 35px;
    box-shadow: 5px 5px 15px rgb(0 0 0 / 15%);
    line-height: 31px;
    font-size: 26px;
    text-shadow: none;
    font-weight: 300;
    color: #fff;
}

.slick-btn .slick-prev {
    left: 0;
}

.slick-btn .sab-4 {
    border-radius: 3px;
}

.slick-btn .slick-next {
    right: 0;
}

.slick-btn .sab-3 {
    border-radius: 3px;
}

.our-partners .slick-btn .carousel-control.right {
    margin-right: 0;
}

.carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
    margin-right: 0;
}

.carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
    margin-left: 0;
}

.our-partners .slick-btn .carousel-control.right {
    margin-left: 0;
}

.recently-properties .slick-btn {
    max-width: 35px!important;
    margin: 0 auto;
    position: relative;
    top: -4px;
}

.slick-btn-section{
    width: 100%;
    display: inline-block;
}

.our-partners .slick-btn-section .slick-btn .carousel-control.right {
    margin-right: -30px;
}

.our-partners .slick-btn-section .slick-btn .carousel-control.left {
    margin-left: 47px;
}

.services-2 .slick-btn{
    max-width: 40px!important;
    margin: 0 auto;
    position: relative;
    top: 0;
}

.agent-section .slick-btn{
    max-width: 40px!important;
    margin: 0 auto;
    position: relative;
    top: 0;
}

/** Sidebar nav start **/
.sidebar-logo{
    padding: 20px 30px;
    border-bottom: solid 1px #00000024;
}

.sidebar-logo img{
    width: 120px;
}

.nav-sidebar {
    width: 350px;
    position: fixed;
    top: 0;
    left: -350px;
    height: 100vh;
    z-index: 999;
    background: #fff;
    color: #fff;
    transition: all 0.3s;
    overflow-y: scroll;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

.nav-sidebar.active {
    left: 0;
}

#dismiss {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: transparent;
    position: absolute;
    top: 23px;
    font-size: 20px;
    right: 20px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#dismiss:hover {
    background: #fff;
}

#sidebar .overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

#sidebar .overlay.active {
    display: block;
    opacity: 1;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

.sidebar-nav {
    display: block;
    z-index: 11;
    overflow: hidden;
    width: 100%;
}

.sidebar-nav-list{
    padding: 0px;
    margin: 0px;
}

.sidebar-nav-list .nav-header {
    margin-bottom: 0;
    color: #eee;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    padding: 15px 10px;
}

.sidebar-nav-list li {
    font-size: 14px;
    padding-left: 15px;
    margin-bottom: 10px;
}

.sidebar-nav-list li a{
    color: #fff !important;
    font-size: 14px;
    padding-left: 30px;
}

.sidebar-nav-list .badge{
    display: inline-block;
    float: right;
    background: #0aa587;
    color: #fff;
}

/** Sidebar nav end **/
.sidebar-navigation {
    height: auto;
    margin: 0 auto;
    padding: 30px 20px 20px;
    border-bottom: solid 1px #00000024;
}

.sidebar-navigation ul {
    margin: 0;
    padding: 0;
}
.sidebar-navigation ul li {
    display: block;
}

.sidebar-navigation ul li a {
    position: relative;
    display: block;
    font-size: 17px;
    font-weight: 500;
    padding: 10px 10px;
    text-decoration: none;
    color: #060606;
    letter-spacing: normal;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.sidebar-navigation .pt0{
    padding-top: 0;
}

.sidebar-navigation .menu-list li a .badge {
    float: right;
    padding: 3px 10px;
    font-size: 11px;
    line-height: 1.3;
    font-weight: 600;
}

.sidebar-navigation .menu-list li a .badge {
    float: right;
    padding: 3px 10px;
    font-size: 11px;
    line-height: 1.3;
    font-weight: 600;
    color: #fff;
}

.sidebar-inner h3{
    margin: 0 0 20px;
    padding: 0 10px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    color: #000;
}

.sidebar-navigation .menu-list li a .badge {
    float: right;
    padding: 3px 10px;
    font-size: 11px;
    line-height: 1.3;
    font-weight: 600;
    color: #fff;
}

.sidebar-navigation ul li a em {
    font-size: 12px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    padding: 4.5px 5px 5.5px;
    border-radius: 5px;
    font-weight: 100;
    color: #777;
}
.sidebar-navigation ul li:hover > a, .sidebar-navigation ul li.selected > a {
    background-color: transparent;
    color: #060606;
    border-color: rgba(255, 255, 255, 0.1);
}

.sidebar-navigation ul li ul {
    display: none;
    background-color: transparent;
}
.sidebar-navigation ul li ul.open {
    display: block;
}

.sidebar-navigation ul li ul li a {
    color: #060606;
    border-color: rgba(255, 255, 255, 0.1);
    font-size: 16px;
    font-weight: 500;
    padding:8px;
}

.sidebar-navigation ul li ul li:hover > a, .sidebar-navigation ul li ul li.selected > a {
    background-color: transparent;
}
.sidebar-navigation ul li ul li:hover > a:before, .sidebar-navigation ul li ul li.selected > a:before {
    margin-right: 10px;
}
.sidebar-navigation ul li ul li.selected.selected--last > a {
    background-color: #94aab0;
    color: #fff;
}
.sidebar-navigation ul li ul li.selected.selected--last > a:before {
    background-color: transparent;
}

/** Get social start **/
.get-social {
    height: auto;
    margin: 0 auto;
    padding: 30px 30px;
    border-bottom: solid 1px #00000024;
}

.get-social a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    font-size: 17px;
    margin-right: 2px;
    border-radius: 5%;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    color: #fff!important;
}

.get-social a:hover{
    color: #fff;
}

.get-social .linkedin-bg {
    background: #1675b9;
}

.get-social h3 {
    padding: 0;
}
/** Get social end **/

/** Get in touch start **/
.get-in-touch {
    height: auto;
    margin: 0 auto;
    padding: 30px 30px;
    border-bottom: solid 1px #00000024;
}

.get-in-touch h3 {
    padding: 0;
}

.get-in-touch .sidebar-contact-info {
    margin: 0 0 10px 0;
    position: relative;
}

.get-in-touch .sidebar-contact-info .icon {
    left: 0;
    position: absolute;
    top: 0;
    transition: background-color .15s linear;
    width: 40px;
    height: 40px;
    text-align: center;
    background: #f1e6e6;
    line-height: 40px;
    margin-right: 15px;
    font-size: 16px;
    color: #060606;
    border-radius: 3px;
}

.get-in-touch .sidebar-contact-info .body-info {
    margin-left: 55px;
}

.get-in-touch .sidebar-contact-info .body-info a {
    color: #060606;
    font-size: 16px;
    line-height: 40px;
    margin-bottom: 0;
    font-weight: 500;
}
/** Get in touch end **/

/** SOCIAL COLOR AREA START **/
.facebook:hover {
    color: #3b589e !important;
}

.twitter:hover {
    color: #56d7fe !important;
}

.linkedin:hover {
    color: #1c82ca !important;
}

.google:hover {
    color: #dc4e41 !important;
}

.rss:hover {
    color: #e78733 !important;
}

.facebook-bg {
    background: #3b589e;
}

.twitter-bg {
    background: #56d7fe;
}

.google-bg {
    background: #dc4e41;
}

.rss-bg {
    background: #e78733;
}

/** FACEBOOK BG AREA START **/
.facebook-bg:hover {
    background: #3b589e!important;
}

.twitter-bg:hover {
    background: #56d7fe!important;
}

.linkedin-bg:hover {
    background: #1c82ca!important;
}

.google-bg:hover {
    background: #dc4e41!important;
}

.rss-bg:hover {
    background: #e78733!important;
}

/** BLOCK HEADING AREA START **/
.option-bar {
    background: #fff;
    border-radius: 3px;
    height: 60px;
    margin-bottom: 30px;
}

.option-bar h4 {
    line-height: 60px;
    display: inline-block;
    padding-right: 20px;
    border-radius: 3px 0 0 3px;
    margin: 0 0 0;
    font-weight: 600;
}

.option-bar .heading-icon {
    width: 60px;
    text-align: center;
    margin-right: 15px;
    display: inline-block;
    border-radius: 3px 0 0 3px;
    position: relative;
    color: #fff;
    height: 60px;
}

.option-bar .sorting {
    padding: 8px 12px;
    background: #FFF;
    border: 1px solid #EEE;
    color: #535353;
    margin-right: 5px;
    font-size: 13px;
    font-weight: 300;
}

.option-bar .sorting-options {
    margin: 10px 15px 0 0;
    text-align: right;
}

.option-bar .sorting {
    font-size: 15px;
    font-weight: 500;
    padding: 9px 12px 7px;
    border: solid 1px #d2d2d2;
    border-radius: 3px;
}

.option-bar .sorting:focus {
    outline: none;
}

.change-view-btn {
    margin-left: 5px;
    border-radius: 2px;
    background: transparent;
    padding: 10px 12.5px 9px;
}

.change-view-btn:hover {
    color: #fff;
}

.active-view-btn {
    color: #fff !important;
    margin-left: 5px;
    padding: 10px 12.5px 9px;
}

.active-view-btn:hover {
    background: transparent;
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #ccc !important;
    filter: "alpha(opacity=20)";
    -ms-filter: "alpha(opacity=20)";
}

/** PORTFOLIO ITEM **/
.portfolio-item {
    margin-bottom: 30px;
}

.properties-gallery .pad {
    padding: 0;
}

.properties-gallery .portfolio-item {
    margin-bottom: 30px;
}

.pg .portfolio-item {
    margin-bottom: 0;
}

/** PORTFOLIO AREA START **/
.portfolio-item, .portfolio-item:focus {
    outline: none;
    margin-bottom: 30px;
    position: relative;
    cursor: pointer;
}

.portfolio-item img, .portfolio-item img:focus {
    outline: none;
    width: 100%;
    height: 100%;
}

.portfolio-item:hover img {
    zoom: 1;
    filter: alpha(opacity=20);
    -webkit-opacity: 0.1;
    opacity: 0.3;
}

.portfolio-item:hover .portfolio-content {
    display: inline;
    -webkit-transition-delay: 0.50s;
    transition-delay: 0.50s;
    background-color: rgba(0, 0, 0, 0.90);
    color: #fff !important;
    z-index: -999;
}

.portfolio-content {
    display: none;
    top: 0;
    width: 100%;
    text-align: center;
    padding: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.8s ease;
    transition: all 0.8s ease;
    text-transform: uppercase;
    color: #1c1c1c;
    height: 100%;
    position: absolute;
    right: 0;
}

.portfolio-content-inner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.portfolio-content p {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
}

.overview-box p {
    color: #333;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
}

.overview {
    z-index: 9;
    cursor: default;
    overflow: hidden;
    text-align: center;
    position: relative;
    margin-bottom: 16px;
    box-shadow: 0 0 3px #ddd;
}

.overview .mask, .overview .content {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 20%;
    left: 0;
}

.overview img {
    display: block;
    position: relative;
    width: 100%;
    height: 232px;
}

.overview .big-img {
    height: 480px;
}

.overview-box img {
    left: 10px;
    margin-left: -10px;
    position: relative;
    -webkit-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
    border: none;
}

.overview-box:hover > img {
    -webkit-transform: scale(2) rotate(10deg);
    transform: scale(2) rotate(10deg);
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=30)";
    filter: alpha(opacity=30);
    opacity: 0.3;
}

.overview h2 {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 18px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.8);
    margin: 20px 0 0 0;
    text-shadow: none;
}

.overview-box h2 {
    color: #333;
    margin: 0 20px 0;
    background: transparent;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
}

.overview a.btn {
    display: inline-block;
    overflow: hidden;
    text-transform: uppercase;
}

.overview-box a.btn {
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all 0.5s linear;
    padding: 4px 20px;
}

.overview-box:hover h2,
.overview-box:hover p,
.overview-box:hover a.btn {
    -webkit-transform: scale(1);
    transform: scale(1);
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}

.latest-offest .col-pad {
    padding: 0 8px;
}

/** ADVANCE SEARCH **/
.show-more-options {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
    display: block;
}

/** PAGINATION AREA START **/
.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 0 0 20px;
    border-radius: 4px;
    text-align: center;
}

.navigation{
    text-align: center;
}

.pagination > li > a, .pagination > li > span {
    border-radius: 0% !important;
    margin: 0 5px 5px;
    text-align: center;
    padding: 7px 14.50px;
    display: inline-block;
}

.pagination > li > a:hover{
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.pagination > li > a {
    font-weight: 400;
}

.pagination > li > a:hover {
    color: #fff;
}

.pagination > .active > a {
}

.pagination > li > a, .pagination > li > span {
    font-size: 18px;
    color: #212529;
    background: #fff;
    border: none;
}

/** FORM CONTENT BOX AREA START **/
.form-content-box {
    max-width: 450px!important;
    color: #4a4747;
    margin: 0 auto!important;
    text-align: center;
    width: 100%;
}

.login-section{
    width: 100%;
    opacity: 1;
    min-height: 100vh;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.form-content-box .details {
    padding: 30px 30px;
    background: #fff;
}

.form-content-box .details h3{
    margin: 0 0 25px;
    font-size: 22px;
    font-weight: 600;
}

.form-content-box .logo{
    padding: 0;
    margin: 0 auto;
    text-align: center;
    float: inherit;
}

.form-content-box .logo img{
    height: 35px;
    text-align: center;
    margin-bottom: 20px;
}

.form-content-box .checkbox label::before {
    width: 0;
    height: 0;
    left: 0;
    margin-left: 0;
}

.form-content-box .checkbox {
    padding-left: 8px;
    margin: 15px 0;
}

.or-text {
    position: absolute;
    top: -10px;
    display: inline-block;
    left: 50%;
    margin-left: -21px;
    color: rgb(153, 156, 164);
    font-size: 14px;
    padding: 0 10px;
    background: #e5e4e4;
}

.form-content-box h3 {
    margin: 0 0 30px;
    font-size: 20px;
    font-weight: 600;
    color: #323232;
}

.form-content-box .input-text {
    padding: 10px 15px;
    outline: none;
    color: #717171;
    width: 100%;
    min-height: 50px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 3px;
    background: transparent;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.form-content-box .form-group {
    margin-bottom: 25px;
}

.form-content-box .footer {
    font-size: 16px;
    padding: 20px;
    background-color: #fff;
    border-top: solid 1px #e8e8e8;
}

.form-content-box .footer span{
    color: #8b8b8b;
}

.form-content-box .footer span a {
    font-weight: 400;
}

.form-content-box .footer span a:hover {
    color: #8b8b8b;
}

.form-content-box .checkbox a {
    color: #8b8b8b;
    font-size: 16px;
}

.form-content-box .checkbox label {
    padding-left: 15px;
    color: #8b8b8b;
    font-size: 16px;
}

.form-content-box .social-list li {
    display: inline-block;
    font-size: 16px;
}

.form-content-box .social-list li a {
    margin: 2px;
    font-size: 16px;
    width: 50px;
    height: 50px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    line-height: 50px;
    color: #fff;
}

.form-content-box  .facebook-bg {
    background: #4867aa;
}

.form-content-box  .twitter-bg {
    background: #56d7fe;
}

.form-content-box  .google-bg {
    background: #dc4e41;
}

.form-content-box  .linkedin-bg {
    background: #1c82ca;
}


.form-content-box  .facebook-bg:hover {
    background: #3b589e;
}

.form-content-box  .twitter-bg:hover {
    background: #4accf3;
}

.form-content-box  .google-bg:hover {
    background: #ca4539;
}

.form-content-box  .linkedin-bg {
    background: #1675b9;
}

/** ERROR PAGE AREA START **/
.error-page {
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 15px;
    background: #1B1B1B;
}

.error-page .error404 {
    line-height: .8;
    margin:0;
    font-weight: 800;
    font-size: 260px;
    opacity: 2;
    color: #3F3F3F;
    text-shadow: 1px 1px 5px rgb(0 0 0 / 40%);
    font-family: 'Jost', sans-serif;
}

.error-page h1 {
    font-size: 34px;
    font-weight: 500;
    margin:0 0 30px;
    color: #dedede;
}

.error-page p {
    margin-bottom: 30px;
    font-size: 18px;
    color: #dedede;
}
/** BLOG AREA START **/
.blog-box {
    margin-bottom: 30px;
    border-radius: 0;
    padding: 0;
    border: none;
    position: relative;
    background: transparent;
}

a:hover {
    text-decoration: none;
    color: #4a4747;
}

.blog-box h3 {
    margin: 0 0 15px;
    font-size: 23px;
    font-weight: 600;
}

.blog-box .detail {
    padding: 30px;
    background: #fff;
}

.read-more {
    font-weight: 400;
    font-size: 16px;
}

.blog-box .date-box {
    position: absolute;
    top: 15px;
    right: 15px;
    display: block;
    letter-spacing: 1px;
    padding: 12px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.blog-box .date-box h5 {
    color: #fff;
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
}

.blog-box .detail .title a:hover {
    color: #7a7677;
}

.blog-box .detail .post-meta {
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: 400;
}

.blog-box .detail .post-meta span {
    margin-right: 15px;
}

.blog-box .detail .post-meta span a i {
    margin-right: 5px;
}

.blog-box .detail p {
    margin-bottom: 20px;
}

.blog-creative .blog-box .detail {
    padding: 20px !important;
}

/** BLOG 1 AREA START **/
.blog-1 {
    margin-bottom: 30px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    position: relative;
    z-index: 2;
    background: #fff;
}

.blog-1:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.blog-1 .blog-photo {
    position: relative;
    transform: scale(1);
    transition: 1s;
}

.blog-1 .blog-inner:hover .blog-photo {
    transform: scale(1.2);
}

.blog-1 .blog-overflow {
    overflow: hidden;
    position: relative;
}

.blog-1 .blog-inner:hover .blog-photo:before {
    width: 100%;
}

.blog-1 .blog-photo:before {
    position: absolute;
    content: '';
    background: linear-gradient(0,#95c41fc2,rgb(255 171 74 / 17%));
    right: 0;
    width: 0%;
    height: 100%;
    transition: 1s;
}

.blog-1 .detail {
    padding: 30px 25px;
    display: inline-block;
    width: 100%;
}

.blog-1 h3 {
    margin: 0 0 15px;
    font-size: 22px;
    font-weight: 600;
}

.blog-1 p{
    margin-bottom: 0;
}

.blog-1 .post-meta {
    margin: 0 0 15px;
    width: 100%;
}

.blog-1 .post-meta li {
    font-size: 15px;
    margin: 0;
    float: left;
    margin-right: 20px;
}

.blog-1 .post-meta li:last-child {
    margin-right: 0;
}

/** Blog 2 start **/
.blog-2{
    position: relative;
    height: 500px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 30px;
    z-index: 2;
    -webkit-transition: .5s;
    transition: .5s;
    overflow: hidden;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.blog-2 img{
    width: 100%;
}

.blog-2:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.blog-2:hover .date-box {
    transform: rotateY(360deg);
}

.blog-2 .date-box {
    position: absolute;
    right: 20px;
    display: block;
    background: #fff;
    padding: 2px;
    transition: 0.9s cubic-bezier(0.24, 0.74, 0.58, 1);
    top: 20px;
    z-index: 999;
    width: 100px;
    height: 100px;
    text-align: center;
    font-size: 16px;
    padding-top: 30px;
    clip-path: polygon(0 0, 100% 0, 100% 75%, 50% 100%, 0 75%);
    border-radius: 5px 5px 0 0;
}


.blog-2:hover .date-box{
    color: #fff;
}

.blog-2:hover .date-box span {
    color: #fff;
}

.blog-2 .date-box span{
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 5px;
    display: block;
    line-height: 22px;
}

.b-photo2 {
    background: url('../img/blog-4.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.b-photo3 {
    background: url('../img/blog-5.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.b-photo4 {
    background: url('../img/blog-6.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.blog-2:before {
    content: "";
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    border-radius: 10px;
    background: linear-gradient(to top,rgb(35 35 37 / 91%) 0%,rgb(35 35 37 / 45%) 35%,rgb(255 255 255 / 0%) 60%,rgb(255 255 255 / 0%) 100%);
    opacity: 1;
}

.blog-2:hover:before {
    background: linear-gradient(0,#547501bd,rgb(255 255 255 / 0%));
}

.blog-2 p{
    color: #fff;
    margin-bottom: 0;
}

.blog-2 h3{
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #fff;
}

.blog-2 h3 a{
    color: #fff;
}

.blog-2 .blog-info{
    position: absolute;
    bottom: 30px;
    left: 25px;
    right: 25px;
    z-index: 999;
}

/** BLOG 3 AREA START **/
.blog-3 {
    margin-bottom: 30px;
    border-radius: 0;
    border: none;
    background: #fff;
    z-index: 2;
    position: relative;
    -webkit-transition: .5s;
    transition: .5s;
    overflow: hidden;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
    text-align: center;
}

.blog-3 .post-meta {
    position: absolute;
    bottom: -20px;
    font-size: 15px;
    border-radius: 5px;
    width: 90%;
    margin: 0 5%;
    padding: 7px 0;
    text-align: center;
    background: #fff;
    box-shadow: 0 0 35px rgb(0 0 0 / 10%);
}

.blog-3 .post-meta span {
    margin: 0 7px;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
}

.blog-3:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.blog-3 .blog-image {
    position: relative;
}

.blog-3 .date-box {
    position: absolute;
    top: 20px;
    font-size: 15px;
    padding: 6px 15px 4px;
    text-align: center;
    right: 20px;
    background: #fff;
    border-radius: 3px;
    display: block;
    z-index: 999!important;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.blog-3:hover .date-box {
    transform: rotateY(360deg);
    color: #fff;
}

.blog-3 .detail {
    padding: 50px 25px 30px;
}

.blog-3 h4{
    margin: 0 0 15px;
    font-size: 20px;
    font-weight: 600;
}

.blog-3:hover .post-meta {
    color: #fff!important;
}

.blog-3:hover .post-meta span{
    color: #fff!important;
}

.blog-3:hover .post-meta a {
    color: #fff;
}

.blog-3:hover .post-meta {
    bottom: 10px;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.blog-3:hover .detail {
    padding: 35px 25px 35px;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

/** SIDEBAR WIDGET AREA START **/
.sidebar-widget {
    background: #fff;
    padding: 20px;
    margin-bottom: 30px;
}

.sidebar .contact-1 .contact-form form textarea {
    min-height: 130px;
}

.contact-1 label {
    font-weight: 600;
    font-size: 14px;
}

.single-block {
    margin-bottom: 30px;
}

.form-search {
    position: relative;
}

.form-search .form-control {
    padding: 10px 17px;
    font-size: 16px;
    outline: none;
    color: #6c6c6c;
    border-radius: 3px;
    min-height: 50px;
    font-weight: 400;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.form-search .btn {
    background: none;
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px 12px 13px 13px;
}

.form-search .btn:active,
.form-search .btn:focus {
    box-shadow: none;
    outline: none;
}

.form-search .btn i {
    color: #4a4747;
}

.form-search .form-group, .form-search .form-control {
    width: 100%;
}

.range-slider {
    margin-top: 15px;
    margin-bottom: 30px;
}

.range-slider .ui-slider {
    position: relative;
    text-align: left;
}

.range-slider label {
    font-size: 15px;
    color: #4a4747;
    font-weight: 600;
    margin-bottom: 12px;
}

.range-slider .ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid #66676b;
    cursor: w-resize;
    outline: none;
    top: -5px;
    margin-left: -8px;
    box-shadow: none !important;
}

.range-slider .ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    display: block;
    border: 0;
    background-position: 0 0;
}

.range-slider .ui-slider .ui-slider-handle {
}

.range-slider .ui-widget-content {
    background-color: #ccc;
}

.range-slider .ui-widget-header {
}

.range-slider .ui-slider.ui-state-disabled .ui-slider-handle, .range-slider .ui-slider.ui-state-disabled .ui-slider-range {
    filter: inherit;
}

.range-slider .ui-slider-horizontal {
    height: 4px;
    border-radius: 10px;
    width: calc(100% - 16px);
    margin-left: 8px;
}

.range-slider .ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%;
}

.range-slider .ui-slider-horizontal .ui-slider-range-min {
    left: 0;
}

.range-slider .ui-slider-horizontal .ui-slider-range-max {
    right: 0;
}

.min-value:focus, .max-value:focus, .min-value, .max-value {
    width: 40%;
    display: inline-block;
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 12px 0 0 0;
    cursor: default;
}

.min-value {
    float: left;
    margin-left: -8px;
    width: 40%;
    margin-right: -8px;
    font-size: 13px;
    color: #4a4747;
    font-weight: 600;
    margin-top: 15px;
}

.max-value {
    float: right;
    width: 60%;
    text-align: right;
    margin-right: -8px;
    font-size: 13px;
    color: #4a4747;
    font-weight: 600;
    margin-top: 15px;
}

.sidebar .properties-box .detail header {
    padding-bottom: 0;
}

.banner-search-box .min-value {
    font-size: 12px;
    font-weight: 400;
}

.banner-search-box .max-value {
    font-size: 12px;
    font-weight: 400;
}

.sidebar-widget .btn{
    display: inline-block;
    padding: 9px 20px 7px 20px;
    margin-bottom: 0;
    font-size: 16px;
}

/** CATEGORY AREA START **/
.category-posts ul {
    margin: 0;
    padding: 0;
}

.category-posts ul li {
    padding: 10px 0;
    border-bottom: dashed 1px #ededed;
    font-size: 16px;
}

.category-posts ul li span {
    float: right;
}

.category-posts ul li:last-child {
    border-bottom: none;
    padding: 10px 0 0;
}

.category-posts ul li a{
    color: #535353;
}

/** TAGS BOX AREA START **/
.tags-box ul li {
    display: inline-block;
}

.tags-box ul li a {
    float: left;
    margin: 0 4px 0 0;
    padding: 6px 12px;
    font-size: 15px;
    font-weight: 400;
    color: #61666d;
    border: solid 1px #f3f3f3;
    background: #f3f3f3;
    border-radius: 2px;
}

.tags-box ul li a:hover {
    color: #fff;
}

/** LATEST REVIWS AREA START **/
.latest-reviews .media {
    margin: 0 0 30px;
}

.latest-reviews .media .media-left {
    padding-right: 20px;
}

.latest-reviews .media .media-left img {
    width: 60px;
    height: 60px;
    border-radius: 5%;
}

.latest-reviews .media-heading {
    font-size: 18px;
    margin: 0 0 5px;
    font-weight: 600;
}

.latest-reviews .media-heading a {
    color: #4a4747;
}

.latest-reviews .media .media-body p {
    margin: 0;
    font-weight: 400;
}

.latest-tweet p {
    font-size: 16px;
    margin: 0 0 20px;
}

.latest-tweet p:last-child {
    margin: 0;
}

/** POPULAR POSTS AREA START **/
.popular-posts .media {
    margin: 0 0 25px;
    color: #4a4747;
}

.popular-posts .media .media-left img {
    width: 65px;
}

.popular-posts .media {
    margin: 0 0 25px;
    color: #4a4747;
}

.popular-posts .media .media-left {
    padding-right: 15px;
}

.popular-posts .media-heading {
    font-size: 18px;
    margin: 8px 0 10px;
    font-weight: 600;
}

.popular-posts .listing-post-meta {
    font-size: 16px;
    font-weight: 500;
}

/** POPULAR POSTS AREA START **/
.archives ul {
    margin: 0;
    padding: 0;
}

.archives ul li {
    line-height: 40px;
    border-bottom: dashed 1px #f1f0f0;
    font-size: 16px;
}

.archives ul li:last-child {
    border-bottom: none;
}

.archives ul li a {
    color: #4a4747;
}

/** BLOG BANNER AREA START **/
.blog-banner {
    padding: 100px 0;
    background: #ececec;
    width: 100%;
    text-align: center;
}

.blog-banner .breadcrumb-area h1 {
    margin: 0 0 10px;
    font-weight: 700;
    font-size: 25px;
    text-transform: uppercase;
    color: #37404d;
}

.blog-banner .breadcrumbs .active {
    margin-left: 7px;
    color: #37404d;
}

.blog-banner .breadcrumbs li {
    display: inline-block;
    list-style: none;
    font-weight: 400;
    font-size: 17px;
}

.blog-banner .breadcrumbs li a {
    color: #37404d;
}

.blog-banner h2 {
    margin: 0;
}

.blog-share {
    float: right;
    text-align: right;
}

.blog-share ul li {
    margin: 0 0 5px 5px;
}

.blog-share ul li a {
    list-style: none;
    float: left;
    font-size: 16px;
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px;
}

.comments {
    list-style: none;
    margin: 0;
    padding: 0;
}

.comment {
    margin: 0 0 30px 0;
    position: relative;
}

.comment-author {
    background-color: #DDDDDD;
    border-radius: 50%;
    height: 60px;
    left: 0;
    position: absolute;
    top: 0;
    transition: background-color .15s linear;
    width: 60px;
}

.comment-author a img {
    bottom: 2px;
    border-radius: 50%;
    display: block;
    height: 60px;
    width: 60px;
    position: absolute;
    top: 0;
}

.t-s {
    margin-top: 40px;
}

.t-s h2 {
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 20px;
    color: #212121;
}

.comment-content {
    margin-left: 80px;
    padding: 0 0 20px;
}

.comment-meta-author {
    float: left;
    font-weight: 600;
    font-size: 17px;
}

.comment-meta-author a {
    font-weight: 600;
}

.comment-meta-author a:hover {
    color: #4a4747;
}

.comment-meta-reply {
    color: #fff;
    float: right;
    font-size: 12px;
    font-weight: 600;
    margin: 0 0 0 5px;
}

.comment-meta-reply a {
    border-radius: 2px;
    color: #fff;
    padding: 4px 8px;
}

.comment-meta-date {
    float: right;
    font-size: .9em;
}

.comment-body {
    color: gray;
    margin-top: 10px;
    line-height: 24px;
}

.comment-rating {
    color: orange;
    margin-bottom: 5px;
}

.comment-rating span{
    color: #535353;
}

.comments ul {
    list-style: none;
    margin: 0 0 0 80px;
    padding: 0;
}

.comment-meta-date span {
    background-color: #cacaca;
    border-radius: 3px;
    color: #fff;
    padding: 4px 8px;
}

/** CONTACT 1 AREA START **/
.contact-1 .contact-form form .input-text {
    width: 100%;
    padding: 10px 17px;
    font-size: 15px;
    outline: none;
    color: #6c6c6c;
    border-radius: 3px;
    min-height: 50px;
    font-weight: 400;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.contact-1 .contact-details .media .media-left i {
    width: 55px;
    font-size: 24px;
    height: 55px;
    line-height: 55px;
    border-radius: 5%;
    text-align: center;
}

.contact-1 .contact-details{
    background: #fff;
    padding: 30px;
    margin-left: 50px;
}

.contact-1 .contact-details h3 {
    font-size: 22px;
    font-weight: 600;
    margin: 0 0 25px;
}

.contact-1 .contact-form .form-group {
    margin-bottom: 25px;
}

.contact-1 .contact-form form textarea {
    min-height: 245px!important;
}

.contact-1 .contact-details .media .media-left {
    padding-right: 20px;
}

.contact-1 .contact-details .media .media-body h4 {
    font-weight: 600;
    font-size: 15px;
    font-style: normal;
    margin: 5px 0 10px;
}

.contact-1 .contact-details .media .media-body p {
    margin-bottom: 5px;
}

.contact-1 .contact-details .media .media-body p {
    font-size: 15px;
    line-height: 22px;
}

.contact-1 .contact-details .media .media-body p a {
    color: #535353;
}

.contact-1 .contact-details .media {
    margin: 0 0 20px;
}

/** CONTACT 2 AREA START **/
.contact-2 .contact-form form .input-text {
    width: 100%;
    padding: 10px 17px;
    font-size: 16px;
    outline: none;
    color: #6c6c6c;
    border-radius: 3px;
    min-height: 50px;
    font-weight: 400;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.contact-2 .contact-form .form-group {
    margin-bottom: 25px;
}

.contact-2 .contact-form form textarea {
    min-height: 200px!important;
}

.contact-2 h3 {
    margin: 0 0 20px;
    font-weight: 600;
    font-size: 22px;
}

.contact-2 .opening-hours {
    margin-bottom: 50px;
    background: #fff;
    padding: 30px;
}

.contact-2 .opening-hours .list-style-none {
    margin: 0;
    padding: 0;
}

.contact-2 .opening-hours .list-style-none li {
    list-style: none;
    line-height: 35px;
    font-size: 15px;
}

.contact-2 .opening-hours strong {
    width: 100px;
    display: inline-block;
    font-weight: 400;
}

.contact-2 .contact-form{
    margin-bottom: 50px;
}


.contact-2 .contact-info{
    text-align: center;
}

.contact-2 .contact-info i {
    font-size: 24px;
}

.contact-2 .contact-info .p22{
    margin: 15px 0 0;
    font-weight: 600;
    font-size: 17px;
}

.contact-2 .contact-info strong {
    font-weight: 500;
    font-size: 15px;
}

.contact-2 .contact-info .contact-info-inner{
    margin-bottom: 40px;
}

/** CONTACT 3 AREA START **/

/** CONTACT 3 AREA START **/
.contact-3 .contact-form form .input-text {
    width: 100%;
    padding: 10px 17px;
    font-size: 16px;
    outline: none;
    color: #6c6c6c;
    border-radius: 3px;
    min-height: 50px;
    font-weight: 400;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.contact-3 .contact-form .form-group {
    margin-bottom: 15px;
}

.contact-3 textarea {
    min-height: 200px!important;
}

.contact-3 .contact-map {
    height: 395px !important;
}

.contact-map {
    height: 300px !important;
}

.contact-map-content .map-content {
    width: 250px;
}

.location .contact-map {
    height: 400px !important;
}

/** ABOUT CITY ESTATE AREA START **/
.about-city-estate {
    padding-top: 100px;
}

.about-city-estate .main-title-2 h1 {
    font-weight: 600;
    font-size: 30px;
}

.about-text p {
    margin-bottom: 20px;
}

.about-text {
    margin: 20px 0 0 10px;
}

.about-text h3 {
    font-weight: 700;
    margin: 0 0 20px;
    text-transform: uppercase;
    font-size: 27px;
}

.pds-2{
    margin-right: 10px;
}

.pds-2 img{
    border-radius: 5px;
}

/** SUB BANNER AREA START **/
.sub-banner {
    background: rgba(0, 0, 0, 0.04) url('../img/img-4.png') top left repeat;
    background-size: cover;
    height: 300px;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
}

.sub-banner.overview-bgi:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.36);
}

.sub-banner .overlay{
    display: inherit;
}

.sub-banner .breadcrumb-area {
    letter-spacing: 1px;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 40%;
    right: 0;
    left: 0;
    z-index: 20;
}

.sub-banner .breadcrumb-area h1 {
    margin: 0 0 5px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    font-size: 30px;
    z-index: 20 !important;
}

.sub-banner .breadcrumbs li {
    display: inline-block;
    list-style: none;
    font-weight: 400;
    font-size: 18px;
}

.sub-banner .breadcrumbs li a {
    color: #fff;
}

.breadcrumbs .active:before {
    content: "/";
    font-family: "FontAwesome";
    font-size: 20px;
    font-weight: 700;
    margin-right: 7px;
}

.breadcrumbs .active {
    margin-left: 7px;
    color: #fff;
}

/** SERVICES INFO 2 AREA START **/
.services-info-2 {
    padding: 0;
    border: none;
}

.services-info-2:hover {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.10), 0 3px 3px 0 rgba(0, 0, 0, 0.10);
}

.services-info-2 img{
    width: 100%;
}

.services-info-2 h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 10px;
}

.services-info-2 .caption {
    padding: 20px;
}

.services-2 .our-partners img {
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    filter: grayscale(0%);
}

.services-2 .read-more {
    font-weight: 600;
    font-size: 16px;
}

/** SERVICES INFO 3 AREA START **/
.services-info-3 {
    background: #fff;
    padding: 20px;
    text-align: center;
    margin-bottom: 30px;
}

.services-info-3:hover {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.10), 0 3px 3px 0 rgba(0, 0, 0, 0.10);
}

.services-info-3 i {
    font-size: 40px;
    margin-bottom: 20px;
}

.services-info-3 h5 {
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0 10px;
}

.services-info-3 p {
    margin-bottom: 0;
    font-size: 15px;
}

.services-info-4 {
    margin: 0 auto 30px;
    position: relative;
    text-align: left;
}

.services-info-4 .icon {
    position: absolute;
    font-size: 60px;
    text-align: left;
    color: #fff;
}

.services-info-4 .detail {
    margin-left: 70px;
}

.services-info-4 h3 {
    font-weight: 600;
    margin: 0 0 10px;
    font-size: 22px;
    color: #fff;
}

.services-info-4 p {
    position: relative;
    font-size: 15px;
    margin-bottom: 0;
    color: #fff;
}

.bg-service-color{
    padding: 30px;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.bg-service-color:before {
    content: "";
    width: 750px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-clip-path: polygon(0 0, 100% 0, 9% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 9% 100%, 0% 100%);
    opacity: 0.5;
}

.cap2{
    margin-left: 100px;
}


.bg-service-color {
    background: #fff;
    padding: 30px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    width: 100%;
}

.bg-service-color:hover {
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.bg-service-color i{
    display: inline-block;
    vertical-align: middle;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.bg-service-color:hover i {
    transform: rotateY(360deg);
}



.bg-service-color .icon {
    font-size: 50px;
}



/** HELPING CENTER AREA START **/
.helping-center {
    margin-bottom: 30px;
    padding-left: 40px;
    text-align: left;
}

.helping-center .icon {
    float: left;
    margin-left: -40px;
}

.helping-center .icon i {
    font-size: 40px;
}

.helping-center h4 {
    font-weight: 600;
    margin: 0 0 5px;
    font-size: 16px;
}

.helping-center  span{
    color: #535353;
    font-size: 15px;
}

.helping-center p {
    font-size: 16px;
    margin: 0;
}

.helping-center p a{
    color: #535353;
}

.helping-center:last-child {
    margin: 0;
}

/** MRG BTM  BIG AREA START **/
.mrg-btm-30 {
    margin-bottom: 30px;
}

.mb-100 {
    margin-bottom: 100px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-20 {
    margin-bottom: 20px;
}

.pb-0{
    padding-bottom: 0;
}

.contact-details .mb-0 {
    margin-bottom: 0 !important;
}

.mbb-50{
    margin-bottom: 50px!important;
}

/** MY PROFILE AREA START **/
.user-account-box {
    background: white;
    margin: 0 auto 30px;
}

.user-account-box .header {
    padding: 40px 0 60px;
    text-align: center;
    position: relative;
    border: none;
    margin: 0;
    background: url('../img/client-bannr.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.user-account-box .header h3 {
    margin: 0 0 5px;
    color: #efefef;
    font-weight: 600;
    font-size: 17px;
    text-transform: uppercase;
}

.user-account-box .header p {
    margin-bottom: 5px;
    color: #efefef;
}

.user-account-box .social-list {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 15px 10px 10px;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
}

.user-account-box .social-list li {
    list-style: none;
    display: inline-block;
}

.user-account-box .social-list li a {
    margin: 0 8px;
    color: #fff;
}

.user-account-box .content {
    padding: 0;
}

.user-account-box .content ul {
    margin: 0;
    padding: 0;
}

.user-account-box .content ul li {
    list-style: none;
    font-weight: 400;
}

.user-account-box .content ul li a {
    border-bottom: 1px solid #f5f5f5;
    padding: 12px 20px 12px 20px;
    display: block;
    color: #535353;
    border-left: solid 5px transparent;
    font-size: 17px;
}

.user-account-box .content ul li a i {
    margin-right: 10px;
}

.user-account-box .content ul li .active {
    font-weight: 600;
}

.my-address form .form-group {
    margin-bottom: 25px;
}

.my-address form .input-text {
    padding: 10px 17px;
    font-size: 15px;
    outline: none;
    color: #717171;
    width: 100%;
    min-height: 50px;
    font-weight: 400;
    border-radius: 3px;
    background: transparent;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.my-address form textarea {
    min-height: 170px!important;
    padding: 20px 17px!important;
}

.my-address form label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 10px;
    font-weight: 400;
    color: #535353;
    font-size: 16px;
}

.my-address {
    background: #fff;
    padding: 30px 30px;
}

.edit-profile-photo {
    position: relative;
    width: 150px;
    height: 150px;
    margin: 0 auto 70px;
}

.edit-profile-photo img {
    width: 100%;
    border-radius: 50%;
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, .4);
    margin-bottom: 20px;
}

.photoUpload {
    background: #fff;
}

.change-photo-btn {
    border-radius: 50px;
    font-size: 13px;
    bottom: -45px;
    left: 15px;
    width: auto;
    background-color: #fff;
    color: #333;
    transition: 0.3s;
    cursor: pointer;
    line-height: 28px;
}

.photoUpload {
    position: relative;
    overflow: hidden;
    border-radius: 50px;
    padding: 3px 25px;
    cursor: pointer;
    color: #fff;
}

.photoUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.my-address {
    margin-bottom: 30px;
}

.my-properties .main-title-2 {
    margin-bottom: 0;
}

.headline {
    font-size: 22px;
    line-height: 32px;
    margin: 0 0 30px 0;
}

blockquote {
    padding: 20px 20px;
    margin: 0 0 20px;
    font-size: 16px;
    line-height: 25px;
    color: #4a4747;
    background: #f9f6f6;
}

.typography-page p {
    font-size: 16px;
    color: #4a4747;
}

.typography-page mark.color {
    color: #fff;
}

mark.color {
    color: #fff;
    border-radius: 3px;
}

mark {
    background: #ff0;
    color: #8a803e;
}

mark, .mark {
    background-color: #fcf8e3;
    padding: 3px 5px;
    border-radius: 3px;
}

.list-3 li:first-child, .list-2 li:first-child, .list-1 li:first-child {
    margin-top: 0;
}

.list-3 li, .list-2 li, .list-1 li {
    list-style: none;
    margin: 14px 0;
    line-height: 27px;
    margin-left: 27px;
    position: relative;
    color: #4a4747;
    font-size: 16px;
}

.list-3, .list-2, .list-1 {
    padding: 5px 0 0 0;
}

.list-2 li:before {
    content: "\f00c";
    font-size: 14px;
}

.list-1 li:before {
    content: "\f0c8";
    font-size: 8px;
}

.list-3 li:before, .list-2 li:before, .list-1 li:before {
    font-family: "FontAwesome";
    margin: 0;
    position: relative;
    color: #66676b;
    float: left;
    margin-left: -27px;
    display: block;
}

.list-3 li:before {
    content: "\f058";
    font-size: 15px;
    top: 1px;
}

.numbered ol {
    counter-reset: li;
    list-style: none;
    padding: 0;
    margin-left: 18px;
    display: inline-block;
}

.numbered ol li {
    display: inline-block;
    padding: 6px 0;
    width: 100%;
    font-size: 16px;
    color: #4a4747;
}

.numbered.color ol > li::before {
    border: 1px solid #274abb;
    color: #274abb;
}

.numbered.color ol > li::before {
    border: 1px solid #66676b;
    color: #66676b;
}

.numbered ol > li::before {
    content: counter(li);
    counter-increment: li;
    font-size: 16px;
    line-height: 34px;
    width: 36px;
    height: 36px;
    display: inline-block;
    border: 1px solid #333;
    border-radius: 50%;
    text-align: center;
    margin: 0;
    margin-left: 0;
    font-weight: 500;
    color: #333;
    position: relative;
    float: left;
    left: -18px;
    transform: translateY(-20%);
}

.numbered.color.filled ol > li::before {
    color: #fff;
}

.numbered.color ol > li::before {
}

.demo h1, .demo h2, .demo h3, .demo h4, .demo h5, .demo h6 {
    line-height: 40px;
}

.mrg-btm-52 {
    margin-bottom: 52px;
}

/** PANEL BOX **/
.panel-box .extra-feature {
    margin: 0;
    padding: 0;
}

.panel {
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: none;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: transparent;
}

.panel-box .extra-feature li {
    list-style: none;
    margin-bottom: 15px;
    font-size: 13px;
    color: #4c4c4c;
    line-height: 16px;
}

.panel-box .panel-heading {
    padding: 0;
    margin-bottom: 10px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: #595757;
    background: none;
}

.panel-box .panel-group .panel + .panel {
    margin-top: 0;
}

.panel-box .panel-heading .panel-title a i {
    margin-right: 15px;
}

.panel-box .panel-heading .panel-title .fa:before {
    font-family: 'FontAwesome';
    content: "\f068"
}

.faq-body h5{
    font-size: 20px;
    color: #37404d;
    font-weight: 600;
}

.panel-box .panel-heading .panel-title .collapsed .fa:before {
    font-family: 'FontAwesome';
    content: "\f067"
}

.panel-box .panel-heading .panel-title a {
    text-decoration: none;
    width: 100%!important;
    background: #fff;
    display: inline-block;
    padding: 18.5px 25px;
}

.panel-box p {
    color: #6d6d6d;
}

.panel-box span a {
    font-weight: 400;
}

.faq-body .panel-box .panel-body {
    padding: 20px 0 30px;
}

.faq-body .nav-tabs {
    border-bottom: 1px solid transparent;
    margin-bottom: 30px;
}

.panel-box .panel-body {
    padding: 0;
}

.panel-box .panel-default {
    border-color: transparent;
    margin-bottom: 0;
}

.panel-box .extra-feature li i {
    margin-right: 15px;
    font-size: 16px;
}

.yes {
    color: #3c763d !important;
    margin-left: 5px;
    font-weight: 600 !important;
    font-size: 12px;
}

.no {
    color: #a94442 !important;
    margin-left: 7px;
    font-weight: 600 !important;
    font-size: 12px;
}

.nav > li {
    position: relative;
    display: block;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
}

.panel-box .nav > li > a {
    position: relative;
    display: block;
    padding: 10px 20px 8px;
    border-radius: 0;
    background: #fff;
    color: #4a4747;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

.panel-box .nav > li {
    margin: 2px 2px 2px 0;
}

.nav-tabs {
    border-bottom: 1px solid transparent;
    margin-bottom: 30px;
}

.nav-tabs > li > a {
    line-height: 1.42857143;
    border: none;
}

.nav-tabs > li > a:hover {
    background: transparent;
    border-radius: 0;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    color: #555;
    cursor: default;
    border: none;
    border-radius: 0;
    border-bottom-color: transparent;
}

/** CAROUSEL CUSTOM AREA START **/
#carousel-custom .carousel-indicators {
    margin: 0;
    overflow: auto;
    position: static;
    text-align: left;
    white-space: nowrap;
    width: 100%;
    padding: 0;
    border: none;
}

#carousel-custom .carousel-indicators li {
    background-color: transparent;
    -webkit-border-radius: 0;
    border-radius: 0;
    display: inline-block;
    height: auto;
    margin: 0 !important;
    width: 12.50%;
    border: none;
    float: left;
}

#carousel-custom .carousel-indicators li img {
    display: block;
    opacity: 1;
}

#carousel-custom .carousel-indicators li.active img {
    opacity: 0.7;
}

#carousel-custom .carousel-indicators li:hover img {
    opacity: 0.7;
}

#carousel-custom .carousel-outer {
    position: relative;
}

.thumb-preview {
    width: 100%;
}

.thumbs {
    width: 100%;
    margin: 12px auto;
}

.thumbs img {
    width: 100%;
    float: left;
}

/** OUR NEWSLATTERS AREA START **/
.our-newslatters {
    text-align: center;
    background: #f1f1f1;
    padding: 40px 0 25px;
}

.our-newslatters h3 {
    margin:0 0 10px;
    font-size: 24px;
    font-weight: 600;
}

.our-newslatters p {
    margin-bottom: 20px;
    font-size: 16px;
}

.our-newslatters .form-info {
    max-width: 600px;
    margin: 0 auto;
}

.our-newslatters .form-control {
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    outline: 0;
    color: #f6f6f6;
    height: 50px;
    border-radius: 3px;
}

.our-newslatters .btn-md {
    height: 50px;
    font-size: 15px;
    text-transform: inherit;
}

/** FLOOR PLANS AREA START **/
.floor-plans table {
    width: 100%;
    margin-bottom: 40px;
    border: 1px solid rgba(0, 0, 0, 0.07);
}

.floor-plans table tr:first-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.floor-plans table td, table th {
    padding: 10px 15px;
    border-right: 1px solid rgba(0, 0, 0, 0.07);
}

.floor-plans table td {
    font-size: 15px;
}

/** PROPERTIES DETAILS PAGE AREA START **/
.properties-details-page p {
    font-size: 16px;
}

.properties-amenities ul {
    margin: 0;
    padding: 0;
}

.properties-amenities li {
    list-style: none;
}

.properties-amenities ul li {
    list-style: none;
    margin-bottom: 10px;
    font-size: 16px;
}

.properties-amenities ul li i {
    margin-right: 10px;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
}

.properties-condition ul {
    margin: 0;
    padding: 0;
}

.properties-condition li {
    list-style: none;
}

.properties-condition ul li {
    list-style: none;
    margin-bottom: 10px;
    color: #4a4747;
    font-size: 16px;
}

.properties-condition ul li i {
    margin-right: 5px;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
}

.sidebar .inside-properties iframe {
    width: 100%;
    border: 0;
    height: 250px !important;
}

.inside-properties iframe {
    width: 100%;
    border: 0;
    height: 400px !important;
}

.sidebar .location .contact-map {
    width: 100%;
    height: 250px !important;
}

.heading-properties h3 {
    font-weight: 700;
    margin-top: 0;
    color: #353434;
}

.heading-properties p {
    margin-bottom: 0;
}

.heading-properties h5 {
    text-align: right;
    font-weight: 600;
    margin: 0;
    font-size: 16px;
}

.Property-description .nav-tabs > li {
    float: left;
    margin-bottom: -1px;
    display: inline-block;
}

/** TABLE MANAGE TABLE PAGE AREA START **/
table.manage-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: none;
}

table.manage-table td:first-child {
    padding-left: 0;
    line-height: 25px;
}

table.manage-table .title-container img {
    width: 20%!important;
    flex: 1.1;
    margin-right: 20px;
}

table.manage-table .title-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

table.manage-table .title-container .title {
    width: 70%;
}

table.manage-table td {
    padding: 30px 20px;
    vertical-align: middle;
    border-bottom: 1px solid #e4e4e4;
    font-size: 15px;
}

table.manage-table .b-none td {
    border-bottom: none;
}

table.manage-table .title-container .title h4 {
    margin: 5px 0 5px;
    display: block;
    font-size: 19px;
    font-weight: 600;
}

table.manage-table .title-container .title h4 a:hover{
    color: #535353;
}

table.manage-table td.action a {
    display: block;
    color: #535353;
    line-height: 20px;
    padding: 3px 0;
    font-size: 15px;
    position: relative;
    margin-left: 21px;
}

table.manage-table .title-container .title .table-property-price {
    display: inline-block;
    color: #535353;
    font-size: 15px;
    font-weight: 600;
}

table.manage-table td.action a i {
    position: absolute;
    left: -21px;
    top: 5px;
}

table.manage-table .title-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

table.manage-table .title-container .title span {
    display: block;
    color: #535353;
    font-size: 15px;
}

/** SUBMIT PROPERTY AREA START **/
.notification-box {
    padding: 30px 35px;
    color: #2e86b2;
    margin-bottom: 50px;
    background-color: #bae8ff;
}

.notification-box h3 {
    margin: 0 0 10px;
    font-weight: 600;
}

.notification-box p {
    font-size: 17px;
    margin: 0;
}

.submit-address form .input-text {
    padding: 10px 20px;
    font-size: 16px;
    outline: none;
    color: #717171;
    width: 100%;
    min-height: 50px;
    font-weight: 400;
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.submit-address .btn{
    padding: 9px 20px 7px;
    margin-bottom: 0;
    font-size: 16px;
}

.submit-address form textarea {
    min-height: 200px!important;
}

.submit-address form label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 16px;
    color: #535353;
}

.submit-address form .form-group {
    margin-bottom: 25px;
}

.dropzone-design {
    border: 2px dashed #d0d0d0;
    min-height: 100px;
}

.dropzone-design, .dropzone-design * {
    box-sizing: border-box;
}

.dropzone-design {
    min-height: 200px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: white;
    padding: 20px 20px;
}

.dropzone-design.dz-clickable {
    cursor: pointer;
}

.dropzone-design.dz-clickable * {
    cursor: default;
}

.dropzone-design.dz-clickable .dz-message, .dropzone-design.dz-clickable .dz-message * {
    cursor: pointer;
}

.dropzone-design.dz-started .dz-message {
    display: none;
}

.dropzone-design.dz-drag-hover {
    border-style: solid;
}

.dropzone-design.dz-drag-hover .dz-message {
    opacity: 0.5;
}

.dropzone-design .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 16px;
    min-height: 100px;
}

.dropzone-design .dz-preview:hover {
    z-index: 1000;
}

.dropzone-design .dz-preview:hover .dz-details {
    opacity: 1;
}

.dropzone-design .dz-preview.dz-file-preview .dz-image {
    border-radius: 3px;
    background: #999;
    background: linear-gradient(to bottom, #eee, #ddd);
}

.dropzone-design .dz-preview.dz-file-preview .dz-details {
    opacity: 1;
}

.dropzone-design .dz-preview.dz-image-preview {
    background: white;
}

.dropzone-design .dz-preview.dz-image-preview .dz-details {
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -ms-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.dropzone-design .dz-preview .dz-remove {
    font-size: 14px;
    text-align: center;
    display: block;
    cursor: pointer;
    border: none;
}

.dropzone-design .dz-preview .dz-remove:hover {
    text-decoration: underline;
}

.dropzone-design .dz-preview:hover .dz-details {
    opacity: 1;
}

.dropzone-design .dz-preview .dz-details {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 13px;
    min-width: 100%;
    max-width: 100%;
    padding: 2em 1em;
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
    line-height: 150%;
}

.dropzone-design .dz-preview .dz-details .dz-size {
    margin-bottom: 1em;
    font-size: 16px;
}

.dropzone-design .dz-preview .dz-details .dz-filename {
    white-space: nowrap;
}

.dropzone-design .dz-preview .dz-details .dz-filename:hover span {
    border: 1px solid rgba(200, 200, 200, 0.8);
    background-color: rgba(255, 255, 255, 0.8);
}

.dropzone-design .dz-preview .dz-details .dz-filename:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropzone-design .dz-preview .dz-details .dz-filename:not(:hover) span {
    border: 1px solid transparent;
}

.dropzone-design .dz-preview .dz-details .dz-filename span, .dropzone-design .dz-preview .dz-details .dz-size span {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 0 0.4em;
    border-radius: 3px;
}

.dropzone-design .dz-preview:hover .dz-image img {
    -webkit-transform: scale(1.05, 1.05);
    -moz-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    -o-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    -webkit-filter: blur(8px);
    filter: blur(8px);
}

.dropzone-design .dz-preview .dz-image {
    border-radius: 3px;
    overflow: hidden;
    width: 120px;
    height: 120px;
    position: relative;
    transition: all 0.3s;
    display: block;
    z-index: 10;
}

.dropzone-design .dz-preview .dz-image img {
    display: block;
    filter: blur(0px);
    transition: all 0.4s;
}

.dropzone-design .dz-preview.dz-success .dz-success-mark {
    -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone-design .dz-preview.dz-error .dz-error-mark {
    opacity: 1;
    -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone-design .dz-preview .dz-success-mark, .dropzone-design .dz-preview .dz-error-mark {
    pointer-events: none;
    opacity: 0;
    z-index: 500;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -27px;
    margin-top: -27px;
}

.dropzone-design .dz-preview .dz-success-mark svg, .dropzone-design .dz-preview .dz-error-mark svg {
    display: block;
    width: 54px;
    height: 54px;
}

.dropzone-design .dz-preview.dz-processing .dz-progress {
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.dropzone-design .dz-preview.dz-complete .dz-progress {
    opacity: 0;
    -webkit-transition: opacity 0.4s ease-in;
    -moz-transition: opacity 0.4s ease-in;
    -ms-transition: opacity 0.4s ease-in;
    -o-transition: opacity 0.4s ease-in;
    transition: opacity 0.4s ease-in;
}

.dropzone-design .dz-preview:not(.dz-processing) .dz-progress {
    -webkit-animation: pulse 6s ease infinite;
    -moz-animation: pulse 6s ease infinite;
    -ms-animation: pulse 6s ease infinite;
    -o-animation: pulse 6s ease infinite;
    animation: pulse 6s ease infinite;
}

.dropzone-design .dz-preview .dz-progress {
    opacity: 1;
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    height: 16px;
    left: 50%;
    top: 50%;
    margin-top: -8px;
    width: 80px;
    margin-left: -40px;
    background: rgba(255, 255, 255, 0.9);
    -webkit-transform: scale(1);
    border-radius: 8px;
    overflow: hidden;
}

.dropzone-design .dz-preview .dz-progress .dz-upload {
    background: #333;
    background: linear-gradient(to bottom, #666, #444);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    -webkit-transition: width 300ms ease-in-out;
    -moz-transition: width 300ms ease-in-out;
    -ms-transition: width 300ms ease-in-out;
    -o-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out;
}

.dropzone-design .dz-preview.dz-error .dz-error-message {
    display: block;
}

.dropzone-design .dz-preview.dz-error:hover .dz-error-message {
    opacity: 1;
    pointer-events: auto;
}

.dropzone-design .dz-preview .dz-error-message {
    pointer-events: none;
    z-index: 1000;
    position: absolute;
    display: block;
    display: none;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -ms-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    border-radius: 8px;
    font-size: 13px;
    top: 130px;
    left: -10px;
    width: 140px;
    background: #be2626;
    background: linear-gradient(to bottom, #be2626, #a92222);
    padding: 0.5em 1.2em;
    color: #fff;
}

.dropzone-design .dz-preview .dz-error-message:after {
    content: '';
    position: absolute;
    top: -6px;
    left: 64px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #be2626;
}

.dropzone-design {
    position: relative;
    border: 2px dashed #d0d0d0;
    border-radius: 3px;
    background: #fcfcfc;
    transition: all 0.3s linear;
    display: inline-block;
    width: 100%;
}

.dropzone-design:before {
    position: absolute;
    height: 100%;
    width: 100%;
    content: "";
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.9));
    background-color: #66676b;
    opacity: 0;
    z-index: 10;
    transition: all 0.3s linear;
    border-radius: 3px;
}

.dropzone-design:hover:before {
    opacity: 0.4;
}

.dropzone-design .dz-message {
    text-align: center;
    margin: 50px 0;
    font-size: 17px;
    color: #4a4747;
    transition: all 0.3s linear;
    position: relative;
    z-index: 11;
}

.dropzone-design:hover .dz-message {
    color: #66676b;
}

.dz-message i {
    display: block;
    font-size: 32px;
    margin-bottom: 15px;
}

.dropzone-design .dz-default.dz-message span {
    display: inline;
    line-height: 117px;
}

.bordered-right {
    border-right: 1px solid rgba(22, 24, 27, .12);
}

/** SPECIFICATIONS AREA START **/
.section-heading .media .media-left i {
    color: #fff;
    width: 47px;
    height: 47px;
    line-height: 47px;
    text-align: center;
    font-size: 30px;
    float: left;
}

.specifications .reviews-box ul {
    margin: 0;
    padding: 0;
}

.specifications .reviews-box ul li {
    list-style: none;
    float: left;
}

.specifications a:hover {
    color: #646363;
}

.book-btn {
    display: block;
    height: 41px;
    margin-bottom: 15px;
    border-radius: 3px;
}

.book-btn-l {
    float: left;
    padding: 0 12px;
    height: 40px;
    display: block;
    text-align: center;
    line-height: 40px;
    color: #fff;
}

.book-btn-r {
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    display: block;
}

/** PAGE LOADER **/
.no-js #loader {
    display: none;
}

.js #loader {
    display: block;
    position: absolute;
    left: 100px;
    top: 0;
}

.page_loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: url('../img/loader.gif') center no-repeat #fff;
}

.slider-section .simple-slider {
    margin-bottom: 30px;
}

.social-media-box {
    text-align: left;
    float: left;
    width: 100%;
}

.social-media-box .social-list li a {
    text-align: center;
    display: inline-block;
    font-size: 18px;
    border-radius: 0;
}

/** Option Panel **/
.option-panel {
    z-index: 9999;
    width: 225px;
    position: fixed;
    right: 0;
    background: #fff;
    box-shadow: 0 0 15px rgb(0 0 0 / 5%);
    top: 20%;
    padding: 20px 20px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.option-panel-collased {
    right: -225px;
}

.option-panel h2 {
    font-size: 16px;
    margin: 0 0 0 0;
    text-align: center;
    padding: 8px 0;
    font-weight: 600;
}

.option-panel .color-plate {
    width: 40px;
    height: 40px;
    float: left;
    margin: 3px;
    border-radius: 0;
    cursor: pointer;
}

.default-plate {
    background: #95c41f;
}

.blue-plate {
    background: #2048bb
}

.yellow-plate {
    background: #ffb400
}

.red-plate {
    background: #d20023;
}

.green-light-plate {
    background: #1abc9c
}

.orange-plate {
    background: #F34B0A;
}

.yellow-light-plate {
    background: #eae328;
}

.green-light-2-plate {
    background: #0dcdbd;
}

.olive-plate {
    background: #b3c211;
}

.purple-plate {
    background: #8e44ad;
}

.blue-light-plate {
    background: #3c9dff;
}

.brown-plate {
    background: #A14C10;
}

.setting-button {
    background: #eee;
    position: absolute;
    font-size: 16px;
    text-align: center;
    width: 50px;
    height: 50px;
    line-height: 50px;
    left: -50px;
    color: #fff;
    /* top: 0; */
    border-radius: 0;
    cursor: pointer;
}

/** Option Panel **/

/** ICONS LIBRARY **/
.icon-lists .heading {
    margin: 0 0 10px 0;
    font-size: 24px;
}

.glyph {
    background: #fff;
    height: 100px;
    float: left;
    text-align: center;
    padding: 23px 11px;
    margin: 10px;
    font-size: 16px;
    color: #535353;
    width: 100%;
}

.glyph .glyph-icon {
    font-size: 24px;
    color: #555;
}

.glyph fieldset,
.glyph .hidden-true {
    display: none;
}

.glyph .bshadow0 .lnr {
    font-size: 26px;
    color: #555;
    display: block;
    margin-bottom: 10px;
}

.icon-lists mark {
    margin-bottom: 10px;
    display: inline-block;
}

.bs-glyphicons-list {
    padding-left: 0;
    list-style: none;
}

.bs-glyphicons li {
    width: 12.5%;
    font-size: 14px;
}

.bs-glyphicons li {
    float: left;
    width: 20%;
    height: 115px;
    padding: 20px 10px 10px;
    font-size: 14px;
    line-height: 1.4;
    text-align: center;
    background-color: #ffffff;
    border: 1px solid #f9f9f9;
}

.bs-glyphicons .glyphicon {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 24px;
}

.bs-glyphicons .glyphicon-class {
    display: block;
    text-align: center;
    word-wrap: break-word;
}

.font-awesome-icons .col-print-4 {
    margin-bottom: 10px;
    font-size: 16px;
}

.font-awesome-icons .text-muted {
    display: none;
}

/** MEDIA QUERY **/
@media (max-width: 1200px) {
    .bdb-2{
        margin-top: 91px;
    }

    .cap2 {
        margin-left: 30px;
    }

    .about-text{
        margin-top: 0;
    }

    .n-1200{
        display: none;
    }

    .a-two .detail {
        padding: 20px 20px 0;
    }

    .a-three .detail{
        padding: 30px 30px 0;
    }

    .a-two .detail h4 {
        font-size: 20px;
    }

    .a-2 .agent-content {
        padding: 25px 20px 0;
    }

    .property-hp img {
        height: 267px;
    }

    /* .banner .banner-tb {
         top: 20%;
     }
 */
    .property-2 .facilities-list li {
        margin-right: 4px;
        padding-right: 4px;
        font-size: 13px;
    }

    .property-2 .content {
        padding: 20px 15px;
    }

    .property-2 .facilities-list {
        padding: 0 12px;
    }
}

@media (max-width: 995px) {
    .testimonials-2 .sec-title-three {
        padding: 0 0 40px;
        text-align: center;
    }

    .bdb-2{
        margin-top: 0;
    }

    .pricing-1{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 12px 0px;
    }

    .about-text {
        margin: 30px 0 0;
    }

    .pds-2 {
        margin-right: 0;
    }

    .contact-1 .contact-details{
        padding: 30px;
        margin-top: 30px;
        margin-left: 0;
    }

    .property-2 .content {
        padding: 20px 20px;
    }

    .property .hp-2 {
        height: auto;
    }

    .rightside-navbar li .button {
        border-radius: 3px;
        padding: 0 7px !important;
    }

    .rightside-navbar {
        padding: 22.5px 0;
    }

    .setting-button {
        font-size: 15px;
        width: 42px;
        height: 42px;
        line-height: 42px;
        left: -42px;
    }

    .logo img {
        height: 35px;
    }

    .bordered-right {
        border-right: 1px solid transparent;
    }

    .pricing-tables-3 .mb {
        margin: 0 auto 40px !important;
    }

    .content-area-8 {
        padding: 100px 0 50px;
    }

    .compare-table tr td {
        font-size: 14px;
        padding: 15px 10px;
    }

    .banner .banner-slider-inner h1 {
        font-size: 39px;
    }

    .banner .banner-slider-inner p {
        font-size: 16px;
    }

    .banner .button-md {
        padding: 12px 20px 8px 20px;
        font-size: 12px;
    }

    /*.banner .banner-tb {
        top: 30%;
    }*/
    .banner-content-left {
        margin-left: auto;
        margin-right: auto;
    }

    .animated {
        /*CSS3 animation properties*/
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;

        /*CSS3 transition properties*/
        -o-transition-property: none !important;
        -moz-transition-property: none !important;
        -ms-transition-property: none !important;
        -webkit-transition-property: none !important;
        transition-property: none !important;

        /*CSS3 transform properties*/
        -o-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -webkit-transform: none !important;
        transform: none !important;
    }

    .modal-raw {
        display: block;
    }

    .banner-detail-box h2 {
        font-size: 37px;
    }
}

@media (max-width: 992px) {
    .testimonials-2 .sec-title-three {
        padding: 0 0 40px;
        text-align: center;
    }

    .banner .item img {
        width: auto!important;
    }

    .banner .carousel-inner>.item>a>img, .carousel-inner>.item>img, .img-responsive, .thumbnail a>img, .thumbnail>img {
        display: block;
        max-width: initial;
        height: auto;
    }

    .main-header .navbar-default .nav > li > a {
        padding: 30px 5px 35px;
        font-size: 14px;
    }

    .bs-glyphicons li {
        width: 33.33%;
    }

    .rightside-navbar li a.button {
        padding: 11px 15px 10px!important;
    }

    .logo {
        margin-right: 25px;
        padding: 27.5px 0;
    }

    .error-page{
        text-align: center;
    }

    .error-page .error404 {
        font-size: 200px;
        margin-bottom: 50px;
    }

    .error-page p {
        margin-bottom: 20px;
        font-size: 17px;
    }

    .error-page h1 {
        font-size: 30px;
        margin: 0 0 20px;
    }

    .banner-tab-btn .search-area {
        padding: 0;
        background: none;
        border-radius: 0;
    }

    .banner-tab-btn .search-area-inner {
        background: transparent!important;
        border-radius: 0;
    }

    .banner-tab-btn .col-pad2 {
        padding: 0 15px;
        border-right: none;
    }

    .banner-tab-btn .tab-btn .search-fields {
        min-height: 50px;
        font-size: 16px;
        border-radius: 3px;
        line-height: 50px;
    }

    .banner-tab-btn form .input-text {
        padding: 5px 20px;
        line-height: 40px!important;
    }

    .banner-tab-btn .search-area .form-group {
        margin-bottom: 10px;
    }

    .banner-tab-btn .search-button {
        padding: 14px 20px;
        border-radius: 3px;
    }

    .banner-tab-btn .search-fields .btn {
        display: inline-block;
        padding: 5px 20px;
        line-height: 40px;
        margin-right: 0;
    }

    .banner-tab-btn .search-fields {
        min-height: 40px;
        line-height: 40px;
    }

    .banner-tab-btn .search-area-inner {
        border-right: none!important;
        -webkit-box-shadow: none;
    }

    .banner-detail-box h3{
        font-size: 39px;
    }

    .counters-2 {
        padding: 80px 0 40px;
    }

    .banner .btn-1 {
        line-height: 41px;
        font-size: 15px;
    }

    .banner .btn-2 {
        font-size: 15px;
        line-height: 41px;
    }

    .counter-box-2 {
        padding: 0;
        margin-bottom: 40px;
    }

    .counters-2 .border-l {
        border-left: none;
    }

    .counters-2 .border-r {
        border-right: none;
    }

    .a-three .detail{
        padding: 43px 30px;
    }

    .a-two .detail h4 {
        font-size: 22px;
    }

    .a-two .detail {
        padding: 43px 30px 0;
    }

    .as2 .a-two .detail {
        padding: 30px 30px 25px;
    }

    .b6 .banner-slider-inner p {
        margin-bottom: 20px;
    }

    .search-area .form-group{
        margin-bottom: 10px;
    }

    .search-area {
        padding: 30px 0 5px;
    }

    .sr2{
        padding-bottom: 20px;
    }

    .sr2 .container-fluid {
        padding: 0 30px;
    }

    .sr2 .btn {
        font-size: 16px;
        height: 50px;
    }

    .sr2 .search-button {
        height: 50px!important;
    }

    .sr2 .search-button{
        height: 45px;
    }

    .property-2 .facilities-list li {
        width: 33%;
        margin-right: 0;
        padding: 0;
    }

    .agent-section-1 .sec-title-three{
        padding: 0;
        margin-bottom: 40px;
        text-align: center;
    }

    .counters-3 .sec-title-three {
        padding: 0;
        margin-bottom: 40px;
        text-align: center;
    }

    .copy-right {
        text-align: center;
    }

    .copy-right p{
        line-height: normal;
        margin-bottom: 15px;
    }

    .copy-right ul {
        float: none;
    }
}

@media all and (min-width: 768px) {
    .our-partners .carousel-inner > .active.left,
    .our-partners .carousel-inner > .prev {
        left: -50%;
    }

    .our-partners .carousel-inner > .active.right,
    .our-partners .carousel-inner > .next {
        left: 50%;
    }

    .our-partners .carousel-inner > .left,
    .our-partners .carousel-inner > .prev.right,
    .our-partners .carousel-inner > .active {
        left: 0;
    }

    .our-partners .carousel-inner .cloneditem-1 {
        display: block;
    }
}

@media all and (min-width: 768px) and (transform-3d), all and (min-width: 768px) and (-webkit-transform-3d) {
    .our-partners .carousel-inner > .item.active.right,
    .our-partners .carousel-inner > .item.next {
        -webkit-transform: translate3d(50%, 0, 0);
        transform: translate3d(50%, 0, 0);
        left: 0;
    }

    .our-partners .carousel-inner > .item.active.left,
    .our-partners .carousel-inner > .item.prev {
        -webkit-transform: translate3d(-50%, 0, 0);
        transform: translate3d(-50%, 0, 0);
        left: 0;
    }

    .our-partners .carousel-inner > .item.left,
    .our-partners .carousel-inner > .item.prev.right,
    .our-partners .carousel-inner > .item.active {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        left: 0;
    }
}

@media all and (min-width: 992px) {
    .our-partners .carousel-inner > .active.left,
    .our-partners .carousel-inner > .prev {
        left: -25%;
    }

    .our-partners .carousel-inner > .active.right,
    .our-partners .carousel-inner > .next {
        left: 25%;
    }

    .our-partners .carousel-inner > .left,
    .our-partners .carousel-inner > .prev.right,
    .our-partners .carousel-inner > .active {
        left: 0;
    }

    .our-partners .carousel-inner .cloneditem-2,
    .our-partners .carousel-inner .cloneditem-3 {
        display: block;
    }

    .testimonials-2 .our-partners .carousel-inner .cloneditem-2,
    .testimonials-2 .our-partners .carousel-inner .cloneditem-3 {
        display: none;
    }
}

@media all and (min-width: 992px) and (transform-3d), all and (min-width: 992px) and (-webkit-transform-3d) {
    .our-partners .carousel-inner > .item.active.right,
    .our-partners .carousel-inner > .item.next {
        -webkit-transform: translate3d(25%, 0, 0);
        transform: translate3d(25%, 0, 0);
        left: 0;
    }

    .our-partners .carousel-inner > .item.active.left,
    .our-partners .carousel-inner > .item.prev {
        -webkit-transform: translate3d(-25%, 0, 0);
        transform: translate3d(-25%, 0, 0);
        left: 0;
    }

    .our-partners .carousel-inner > .item.left,
    .our-partners .carousel-inner > .item.prev.right,
    .our-partners .carousel-inner > .item.active {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        left: 0;
    }
}

@media (max-width: 768px) {
    .counters h3 {
        font-size: 19px;
        margin-bottom: 20px;
    }

    .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
        background-color: transparent;
    }

    .modal-dialog{
        margin: 100px auto 30px;
    }

    .footer-item.fi2{
        margin-right: 0;
    }

    .navbar-nav {
        margin: 0px 0px!important;
        background: #fff5f5;
    }

    .navbar-collapse{
        padding: 0;
    }

    .panel-box .nav > li > a {
        font-size: 13px;
        letter-spacing: normal;
    }

    .bs-glyphicons li {
        width: 50%;
    }

    .error-page .error404 {
        font-size: 160px;
    }

    .error-page p {
        font-size: 17px;
    }

    .error-page h1 {
        font-size: 26px;
    }

    .intro-section .intro-section-inner {
        padding: 30px 0;
    }

    .intro-section {
        text-align: center;
    }

    .intro-section h3 {
        margin-bottom: 15px;
        line-height: normal;
        font-size: 24px;
    }

    .intro-section .btn-2 {
        float: none;
    }

    .counter-box-inner{
        max-width: 262px;
        margin: 0 auto;
    }

    .counter-box-1 h1 {
        font-size: 40px;
    }

    .counters-1 {
        padding: 70px 0 40px;
    }

    .counter-box-3 {
        max-width: 345px!important;
        margin: 0 auto 15px;
        float: initial;
    }

    .counters-2 {
        padding: 70px 0 30px;
    }

    .a-three .detail{
        padding: 30px;
    }

    .testimonials-1{
        padding: 70px 0;
    }

    .a-two .detail {
        padding: 30px;
    }

    .main-header .navbar-default .nav > li > a{
        font-size: 14px;
    }

    .sr2 .container-fluid {
        padding: 0 5px;
    }

    .view-search {
        margin: 0 auto 30px;
    }

    .n-786{
        display: none;
    }

    .banner-detail-box{
        text-align: center;
    }

    .contact-2 .contact-info .contact-info-inner {
        margin-bottom: 30px;
    }

    .main-title p{
        font-size: 16px;
    }

    .property-hp .property-content .info{
        padding: 25px 20px 20px;
    }

    .property-hp .property-content .property-footer {
        padding: 15px 20px;
    }

    .property-hp .property-content .title {
        font-size: 22px;
    }

    .counters-3 .counter-box-3 {
        padding: 20px 12px 15px;
    }

    .our-service-there {
        padding-top: 70px;
    }

    .our-service-there h1 {
        margin: 0 0 25px;
        font-size: 24px;
    }

    .counters-3{
        padding: 70px 0 55px;
    }

    .counters-3 .sec-title-three {
        margin-bottom: 25px;
    }

    .counters-3 .sec-title-three h2 {
        font-size: 24px;
    }

    .counters {
        padding: 50px 0 20px;
    }

    .agent-section-1 .sec-title-three{
        margin-bottom: 25px;
    }

    .agent-section-1 h2 {
        font-size: 24px;
    }

    .agent-section-1 .item {
        margin: 0;
    }

    .agent-section-1 {
        padding: 50px 0;
    }

    .pr-0{
        padding-right: 15px;
    }

    .testimonials-2 {
        padding: 70px 0 125px;
    }

    .button-section.mb-50{
        margin-bottom: 40px;
    }

    .contact-2 .mb-60 {
        margin-bottom: 40px;
    }

    .contact-3 .mb-60{
        margin-bottom: 40px;
    }

    .contact-3 .contact-info {
        margin-bottom: 50px;
    }

    .main-header .navbar-nav .open .dropdown-menu .dropdown-header, .navbar-nav .open .dropdown-menu>li>a {
        padding: 10px 15px!important;
        font-size: 16px;
        border-top: solid 0px!important;
        letter-spacing: normal;
    }

    .main-header .navbar-nav .open .dropdown-menu .dh2{
        padding: 6px 0!important;
        font-size: 16px;
        border-bottom: 1px solid #fff;
    }

    .mega-dropdown .mega-dropdown-menu {
        padding: 15px 5px;
        background: #fff5f5!important;
    }

    .mega-dropdown-menu > li > ul > li > a {
        font-size: 16px;
    }

    .main-header .dropdown-header.dh2{
        padding: 6px 0!important;
    }

    .property .hp-1 {
        height: auto;
    }

    .option-panel {
        top: 10%;
    }

    table.manage-table td {
        padding: 15px 10px;
        vertical-align: middle;
        border-bottom: 1px solid #e4e4e4;

    }

    table.manage-table .action{
        width: 85px;
    }

    table.manage-table .action a{
        text-align: right;
    }

    table.manage-table td.action a i {
        position: initial;

    }

    table.manage-table .title-container img {
        max-width: 120px!important;
        flex: 1.1;
        margin-right: 20px;
    }

    .expire-date{
        display: none;
    }

    table.manage-table td.action a {
        margin-left: 0;
    }

    .slider-mover-left i {
        font-size: 18px;
    }

    .slider-mover-right i {
        font-size: 18px;
    }

    .brand-box {
        padding: 20px 10px;
    }

    .compare-properties h3 {
        margin: 0 0 15px;
        font-size: 18px;
    }

    footer {
        padding: 70px 0 0;
    }

    .brand-box img {
        height: 40px;
    }

    .brand-box h5 {
        font-weight: 600;
        margin: 20px 0 10px;
    }

    .slider-mover-right i {
        font-size: 18px;
    }

    .property .button, input[type="submit"] {
        padding: 5px 12px;
    }

    .property-price {
        font-size: 17px;
    }

    .logo img {
        height: 30px;
    }

    .properties-panel-box .nav > li > a {
        font-size: 12px;
        padding: 8px 15px;
    }

    .mb-70 {
        margin-bottom: 40px;
    }

    .mb-100 {
        margin-bottom: 70px;
    }

    .search-area .container {
        padding: 0 5px;
    }

    .pricing-tables-3 .mb {
        margin: 0 auto 25px !important;
    }

    .comment {
        margin-bottom: 0;
    }

    .about-city-estate .main-title-2 h1 {
        font-size: 24px;
    }

    .about-city-estate {
        padding-top: 70px;
    }

    .error404-content h2 {
        font-size: 22px;
    }

    .breadcrumbs li {
        font-size: 16px;
    }

    .list-inline-listing li {
        padding: 5px 15px 3px;
        margin: 0 0 3px;
    }

    .biography p {
        text-align: justify;
        font-size: 14px;
        line-height: 20px;
    }

    .main-title h1 {
        font-size: 24px;
    }

    .property-hp img {
        height: auto;
    }

    .main-title-2 {
        margin-bottom: 20px;
    }

    .content-area {
        padding: 70px 0 40px;
    }

    .content-area-2 {
        padding: 70px 0;
    }

    .content-area-3 {
        padding: 20px 0;
    }

    .content-area-4 {
        padding: 50px 0;
    }

    .content-area-5 {
        padding-bottom: 20px;
    }

    .content-area-6 {
        padding: 70px 0 40px;
    }

    .content-area-7 {
        padding: 70px 0;
    }

    .content-area-8 {
        padding: 70px 0 40px;
    }

    .content-area-9 {
        padding-top: 50px;
    }

    .content-area-10 {
        padding: 70px 0 55px;
    }

    .content-area-11 {
        margin-bottom: 70px;
    }

    .content-area-12 {
        padding: 70px 0 50px;
    }

    .content-area-13 {
        margin-bottom: 70px;
        padding-bottom: 15px;
    }

    .content-area-14 {
        margin-bottom: 70px;
        padding:70px 0 20px;
    }

    .content-area-15 {
        padding: 70px 0 30px;
    }

    .contact-2 .opening-hours{
        margin-bottom: 30px;
    }

    .contact-2 .contact-form {
        padding: 0;
        background: transparent;
    }

    .contact-2 .contact-details {
        padding: 0;
        background: transparent;
    }

    .categories-2 {
        padding: 50px 0 30px;
    }

    .main-title {
        margin-bottom: 30px;
    }

    .mb-40 {
        margin-bottom: 20px;
    }

    .listings-parallax {
        padding-top: 50px;
        text-align: center;
    }

    .contect-agent-photo {
        margin-top: 15px;
    }

    .our-service-inner {
        padding: 50px 0;
    }

    .properties-panel-box .nav-tabs > li > a {
        margin: 2px 4px 2px 0;
    }

    .bc-2{
        max-width: 90%;
        margin: 0 auto!important;
    }

    .banner .banner-slider-inner h1 {
        font-size: 30px;
    }

    .logo-2 {
        margin-bottom: 10px;
        text-align: center;
    }

    .location .contact-map {
        height: 250px !important;
    }

    .logo {
        padding: 25px 0;
    }

    .navbar-default .navbar-toggle {
        margin-top: 33px;
        margin-right: 0;
        padding: 0;
        margin-bottom: 8px;
        border-radius: 0;
        border: none;
    }

    .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
        color: #fff !important;
    }

    .main-header .navbar-default .nav > li > a:hover {
        color: #fff !important;
        background: transparent;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus, .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
        color: #fff;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #fff;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #555;
        background: #fff5f5;
        border-bottom: none;
    }

    .navbar-nav > .dropdown > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li.dropdown-header, .navbar-nav > .dropdown > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li > a {
        background: #fff5f5;
        padding-left: 50px;
    }

    .rightside-navbar {
        display: none;
    }

    .main-header .navbar-default .nav > li > a {
        padding: 10px 15px 10px!important;
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        border-top: solid 0px!important;
        text-transform: inherit;
    }

    .main-header .navbar-default .nav > li > a:hover{
        padding: 10px 15px 10px!important;
    }

    .hidden-mb-0 {
        margin-bottom: 0;
    }

    .section-4 {
        padding-top: 95px;
    }

    .listings-parallax h1 {
        font-size: 25px;
        margin: 0 0 20px;
    }

    .sub-banner h1 {
        font-size: 28px;
    }

    .sub-banner {
        height: 240px;
    }

    .blog-banner .breadcrumb-area h1 {
        font-size: 22px;
    }

    .blog-banner .breadcrumbs li {
        font-size: 16px;
    }

    .blog-banner {
        padding: 65px 0;
    }

    .sub-banner .breadcrumbs li {
        font-size: 16px;
    }

    .contact-details .media .media-left {
        padding-right: 20px;
    }

    .contact-details .media .media-left i {
        width: 42px;
        font-size: 21px;
        height: 42px;
        line-height: 42px;
    }

    .blog-box .detail p {
        text-align: justify;
    }

    .search-contents {
        padding: 0 15px;
        border-radius: 0;
    }

    .search-box .form-group {
        margin-bottom: 0;
    }

    .blog-box .detail {
        padding: 25px 20px;
    }

    .blog-box .detail .tags-box {
        margin-bottom: 20px;
    }

    .blog-box .detail .blog-share {
        float: left;
        text-align: left;
    }

    .blog-box h3 {
        font-size: 20px;
    }

    .comments ul {
        margin: 0 0 0 0;
    }

    .comment-body {
        line-height: 20px;
        margin-top: 10px;
        font-size: 13px;
        text-align: justify;
    }

    blockquote {
        margin: 20px 0 20px;
    }

    .blog-banner {
        text-align: center;
    }

    .go-home {
        float: none;
    }

    .blog-banner h2 {
        margin: 0 0 30px 0;
        font-size: 20px;
    }

    .option-bar h4 {
        display: block;
        border-radius: 3px;
    }

    .option-bar .cod-pad {
        padding: 0 15px 0 0;
    }

    .form-content-box .details {
        padding: 20px 20px;
    }

    .sec-title-three h2 {
        margin-top: 0;
    }

    .recent-properties {
        padding: 50px 0 20px;
    }

    .about-text h3 {
        font-size: 24px;
    }

    .slider-mover-left {
        width: 30px;
        line-height: 50px;
        height: 50px;
        left: 2px;
    }

    .slider-mover-right {
        width: 30px;
        line-height: 50px;
        height: 50px;
        right: 2px;
    }

    .banner-detail-box h3{
        font-size: 30px;
    }

    .banner-detail-box h2 {
        font-size: 30px;
    }

    .search-area .form-group {
        margin-bottom: 10px;
    }

    .properties-box .detail .location a {
        font-size: 14px;
    }

    .about-box {
        max-width: 100%;
    }

    .property .height img {
        height: auto;
    }

    .properties-details-page p {
        text-align: justify
    }

    .properties-amenities ul li {
        list-style: none;
    }

    .properties-condition ul li {
        margin-bottom: 10px;
    }

    .heading-properties h3 {
        font-size: 18px;
    }

    .typography-page p {
        font-size: 13px;
        line-height: 25px;
        text-align: justify;
    }

    .sub-banner .breadcrumb-area h1 {
        font-size: 24px;
    }

    .breadcrumbs .active:before {
        font-size: 18px;
    }

    .breadcrumb-area p {
        font-size: 14px;
    }

    .breadcrumb-area .button-md {
        padding: 10px 20px;
    }

    .testimonials-3 h1 {
        margin-bottom: 25px;
        font-size: 24px;
    }

    .testimonials-3 {
        padding: 70px 0 125px;
    }

    .listings-parallax img {
        margin-top: 30px;
    }

    .t-slider-l {
        width: 30px;
        height: 30px;
        line-height: 25px;
    }

    .t-slider-r {
        width: 30px;
        height: 30px;
        line-height: 25px;
    }

    .animated {
        /*CSS3 animation properties*/
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;

        /*CSS3 transition properties*/
        -o-transition-property: none !important;
        -moz-transition-property: none !important;
        -ms-transition-property: none !important;
        -webkit-transition-property: none !important;
        transition-property: none !important;

        /*CSS3 transform properties*/
        -o-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -webkit-transform: none !important;
        transform: none !important;
    }

    .banner .banner-slider-inner p {
        font-size: 15px;
    }

    .testimonials-2 .sec-title-three {
        padding: 0 0 25px;
    }

    .testimonials-2 h2 {
        font-size: 24px;
    }

}

@media (min-width: 768px) {

    .main-header .navbar-default .nav > li:hover > .dropdown-menu {
        display: block;
        animation-delay: 0.3s;
        -webkit-animation-delay: 0.3s
    }

    .main-header .navbar-default .nav > li > .dropdown-menu li:hover > .dropdown-menu {
        display: block;
    }
}

/** List view inside Map **/
.map-properties-list {
    margin: 0px 15px 30px 15px;
}

.img-inside-map {
    height: 225px;
}


